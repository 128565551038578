import { ApiEmployee } from './Api';
import { MaternityLeaveAbsence, ParentalLeaveAbsence, SabbaticalLeaveAbsence } from './Employee';

export enum AbsenceAction {
  Creation = 'CREATION',
  Deletion = 'DELETION',
  Update = 'UPDATE',
}

export interface AbsenceDashboardAbsenceItem {
  eventId: string;
  absenceId: string;
  absenceType: string;
  start: Date;
  eventStart: Date;
  end: Date;
  employee: ApiEmployee;
  processedByPayroll: boolean;
  createdAt: Date;
  details: MaternityLeaveAbsence | ParentalLeaveAbsence | SabbaticalLeaveAbsence;
  action: AbsenceAction;
}
