import { primary3, textDisabled } from 'shared/constants/colors';
import styled from 'styled-components';
import { InoNavDrawer } from '@inovex.de/elements-react';

export const InoNavDrawerStyled = styled(InoNavDrawer)`
  --ino-nav-drawer-width-open: 210px;
  --ino-nav-item-color-active: ${primary3};
  --ino-nav-item-color: ${textDisabled};
`;

export const Main = styled.main`
  height: 100%;
`;
