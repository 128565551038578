import * as React from 'react';
import { iconGreyMedium } from 'shared/constants/colors';
import { CustomInoIcon } from 'shared/style/CustomInoIcon';
import { InoTooltip } from '@inovex.de/elements-react';
import {
  ListDetail,
  DetailAttribute,
  DetailAttributeText,
  DetailAttributeIcon,
  AbsenceWrapper,
} from './AbsencesDetail';
import { AbsenceDashboardAbsenceItem } from 'shared/types/interfaces/Absences';
import { AbsenceDetailHeader } from './AbsenceDetailHeader';
import { InoHeader } from 'shared/components/InoHeader';

interface ParentalLeaveProps {
  id: number;
  start: string;
  end: string;
  absence: AbsenceDashboardAbsenceItem;
}

export const ParentalLeaveAbsenceDetails: React.FunctionComponent<React.PropsWithChildren<ParentalLeaveProps>> = (
  props
) => (
  <AbsenceWrapper>
    <InoHeader $firstHeading>Elternzeit</InoHeader>
    <AbsenceDetailHeader start={props.absence.start} end={props.absence.end}></AbsenceDetailHeader>
    <ListDetail>
      <li id={'li-no-detail-' + props.id}>
        <DetailAttribute>
          <DetailAttributeText>Keine Details</DetailAttributeText>
          <DetailAttributeIcon>
            <CustomInoIcon
              src={process.env.PUBLIC_URL + '/icons/no-details-icon.svg'}
              color={iconGreyMedium}
              size={'1.2rem'}
            ></CustomInoIcon>
          </DetailAttributeIcon>
        </DetailAttribute>
      </li>
      <InoTooltip for={'li-no-detail-' + props.id} placement="right" trigger="mouseenter" colorScheme={'light'}>
        {'Keine weiteren Details'}
      </InoTooltip>
    </ListDetail>
  </AbsenceWrapper>
);
