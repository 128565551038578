import _ from 'lodash';
import { GroupedEmployeeSalaryAdjustments } from './SalaryOverview';

export const getMonthGroupHeaderText = (monthDate: string, count = 0) => `${monthDate} (${count.toString()})`;

export const sortMonthGroups = (monthGroups: GroupedEmployeeSalaryAdjustments) => {
  const monthGroupsCpy = { ...monthGroups };

  Object.keys(monthGroupsCpy).forEach((month: string) => {
    monthGroupsCpy[month] = _.orderBy(
      monthGroupsCpy[month],
      ['processedByPayroll', 'from', 'employee.name.lastName'],
      ['asc', 'desc', 'asc']
    );
  });

  return monthGroupsCpy;
};
