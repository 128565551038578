import { FetchError } from 'apis/FetchError';
import { GroupedEmployeeSalaryAdjustments } from './SalaryOverview';
import _ from 'lodash';
import { sortMonthGroups } from './utils';
import { SalaryAdjustmentContext } from 'shared/types/interfaces/Employee';

export type Action =
  | {
      type: 'toggle-performed-by-payroll';
      payload: {
        from: string;
        salary: number;
        context: SalaryAdjustmentContext;
        processedByPayroll: boolean;
        employeeId: string;
      };
    }
  | {
      type: 'set-error';
      payload: FetchError | null;
    }
  | {
      type: 'set-success-message';
      payload: boolean;
    }
  | {
      type: 'set-salary-data';
      payload: GroupedEmployeeSalaryAdjustments;
    };

export interface EmployeeSalaryAdjustmentsState {
  salariesByMonth: GroupedEmployeeSalaryAdjustments | null;
  error: FetchError | null;
  successMsg: boolean;
}

export const reducer = (state: EmployeeSalaryAdjustmentsState, action: Action) => {
  switch (action.type) {
    case 'set-error':
      return {
        ...state,
        error: action.payload,
      };
    case 'set-success-message':
      return {
        ...state,
        successMsg: action.payload,
      };
    case 'set-salary-data':
      return {
        ...state,
        salariesByMonth: sortMonthGroups(action.payload),
      };
    case 'toggle-performed-by-payroll':
      const salariesByMonth = Object.entries(state.salariesByMonth as GroupedEmployeeSalaryAdjustments).reduce(
        (prev, [month, salaries]) => ({
          ...prev,
          [month]: salaries.map((salary) => ({
            ...salary,
            processedByPayroll:
              salary.from === action.payload.from &&
              salary.salary.value === action.payload.salary &&
              salary.context === action.payload.context &&
              salary.employee.id === action.payload.employeeId
                ? action.payload.processedByPayroll
                : salary.processedByPayroll,
          })),
        }),
        {} as GroupedEmployeeSalaryAdjustments
      );

      return {
        ...state,
        salariesByMonth: sortMonthGroups(salariesByMonth),
      };
  }
};
