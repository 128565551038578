import styled, { css } from 'styled-components';
import { grey, greyLight, headingDecorator, hover, primary1, textLight } from 'shared/constants/colors';

interface ColumnProps {
  $numberOfColumns: number;
}

export const Table = styled.table`
  border-spacing: 0;
  width: 100%;
`;

export const Tr = styled.tr<{ theme?: string; color?: string }>`
  &:hover {
    background: ${hover};
  }
  &:last-child {
    border-bottom: none;
  }
  ${(props) =>
    props.theme === 'hovered' &&
    css`
      background: ${hover};
    `}
  ${(props) =>
    props.color === 'grey' &&
    props.theme !== 'hovered' &&
    css`
      background: ${headingDecorator};
    `}
`;

export const Td = styled.td<{ theme: string }>`
  text-align: left;
  padding: 0.375rem 0.5rem 0.375rem 1rem;
  font-size: 0.875rem;
  color: ${textLight};
  ${(props) =>
    props.theme === 'hovered' &&
    css`
      color: ${primary1};
      cursor: pointer;
    `}
  ${(props) =>
    props.theme === 'border' &&
    css`
      border-bottom: ${greyLight} solid 0.0625rem;
    `}
`;

export const TrContract = styled(Tr)`
  background-color: ${grey};
  ${(props) =>
    props.theme === 'hovered' &&
    css`
      color: ${primary1};
      cursor: pointer;
    `}
`;

export const TdContract = styled(Td)`
  font-size: 0.875rem;
  padding: 0.875rem 0.5rem 0.875rem 1rem;
  border-bottom: ${greyLight} solid 0.0625rem;
  &:hover {
    color: ${primary1};
    cursor: pointer;
  }
`;

export const TdHeader = styled(Td)`
  font-size: 0.8rem;
  padding: 0.875rem 0.5rem 0.875rem 1rem;
  border-bottom: ${greyLight} solid 0.0625rem;
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
`;

export const ColumnContainer = styled.div<ColumnProps>`
  ${(props: ColumnProps) =>
    props.$numberOfColumns === 5 &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      column-gap: 1.875rem;
      margin-top: 0.3125rem;
    `}

  ${(props: ColumnProps) =>
    props.$numberOfColumns === 4 &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 1.875rem;
      padding-left: 0.3125rem;
    `}

  ${(props: ColumnProps) =>
    props.$numberOfColumns === 3 &&
    css`
      grid-column: span 3;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    `}

  ${(props: ColumnProps) =>
    props.$numberOfColumns === 2 &&
    css`
      grid-column: span 2;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    `}
`;

export const ContentSection = styled.div`
  margin-right: 2rem;
`;

export const InoHeaderContainer = styled.div<{ $firstHeading?: boolean }>`
top: 0;
z-index: 1;
width: 100%;
background: white;
position: sticky;
${({ $firstHeading }) =>
  $firstHeading
    ? css`
        margin: 0 0 1rem 0;
      `
    : css`
        margin: 2rem 0 1rem 0;
      `}}
`;

export const InoHeaderStyled = styled.h1<{ $firstHeading?: boolean }>`
  font-size: 1rem;
  font-weight: bold;
  color: #525368;
  width: fit-content;
  background: linear-gradient(0, rgba(212, 204, 255, 0.5) 40%, transparent 40%);
  padding: 0 3px;
`;

export const InoSectionH1 = styled.h1`
  display: block;
  width: 100%;
  z-index: 1;
  position: sticky;
  top: 0;
  background: white;
  text-transform: uppercase;
  margin: 0 0 2rem 0;
`;

export const InoSectionH2 = styled.h1`
  display: block;
  width: 100%;
  z-index: 1;
  position: sticky;
  top: 0;
  background: white;
  text-transform: uppercase;
  margin: 2rem 0 2rem 0;
`;

export const TimeTable = styled.div`
  display: table;
  width: 100%;
`;
