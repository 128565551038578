import { InternshipType } from 'shared/types/enums/InternshipType';
import { Salutation } from 'shared/types/enums/Salutation';
import { Recruiting } from '../enums/Recruiting';
import { Level } from '../enums/Level';
import { SalaryPeriod } from '../enums/SalaryPeriod';

export enum LeaveLengthAfterBirth {
  W8 = 8,
  W12 = 12,
}

export interface BaseEmployee {
  name: Name;
  holidayEntitlement: HolidayEntitlement;
  degree: string;
  birthday: Date | string;
  privateContact: PrivateContact;
}

export interface SharedEmployee extends BaseEmployee {
  id: string;
  username: string;
  businessContact: BusinessContact;
  personnelNumber: number;
  recruitmentInformation: Recruitment;
  referredById?: string | null;
  salaryAdjustments: SalaryAdjustment[];
}

export interface Employee extends SharedEmployee {
  employmentPeriods: EmploymentPeriod[];
  birthday: Date | string;
  firstPeriod: EmploymentPeriod;
  closestPeriod: EmploymentPeriod;
  contracts: Contract[];
  closestContract: Contract;
  lastPeriod: EmploymentPeriod;
  emergencyContact: EmergencyContact | null;
  note: string | null;
  performanceReviewMonth: number;
  absence: ZepAbsence | undefined;
  absences: Absences;
  holiday: Holiday;
  expectedEndOfStudy: string | null;
  activeOffboarding: boolean;
}

export interface EmergencyContact {
  firstName: string;
  lastName: string;
  email: string | null;
  telephone: string | null;
}

export interface Recruitment {
  source: Recruiting;
  sourceDetail: string;
  level: Level;
}

export interface EmployeeShortInfo {
  id: string;
  name: Name;
  username: string;
  businessContact: BusinessContact;
}

export interface Name {
  title: string;
  firstName: string;
  lastName: string;
  salutation: Salutation;
}

export interface Location {
  id: string;
  shortName: string;
  name: string;
}

export interface EmploymentPeriod {
  id: string;
  from: string | Date;
  to: string | Date | null;
  team: Team | null;
  employmentStatus: EmploymentStatus | null;
  costCentre: CostCentre | null;
  teamLead: EmployeeShortInfo | null;
  mentor?: EmployeeShortInfo | null;
  planningGroup: PlanningGroup;
  workingHours: WorkingHours;
  jobTitle?: string;
  probationEnd?: string | Date | null;
  ebr: number;
  academicSubject?: AcademicSubject | null;
  location?: Location;
}

export interface Contract {
  from: string | Date;
  to: string | Date | null;
  employmentPeriodsIds?: string[];
}

export interface PlanningGroup {
  id: number;
  name: string;
  shortName: string;
}

export interface EmploymentStatus {
  id: number;
  name: string;
  shortName: string;
  detail: string;
  isBachelorStudent?: boolean;
  isMasterStudent?: boolean;
  isWorkingStudent?: boolean;
  isIntern?: boolean;
  isLeading?: boolean;
  isOperative?: boolean;
  isApprenticeship?: boolean;
}

export interface Team {
  id: string;
  parentId: string | null;
  name: string;
}

interface CostCentre {
  id: number;
  name: string;
}

interface PrivateContact {
  street: string | undefined;
  zipcode: string | undefined;
  city: string | undefined;
  email: string | undefined;
  telephone: string | undefined;
}

export interface BusinessContact {
  email: string;
  telephone: string;
}

export interface Salary {
  value: number | '';
  period: SalaryPeriod | '';
}

export interface EmployeeAcademicSubject {
  topic: string | null;
  supervisorId: string | null;
  internshipType: InternshipType | null;
}

export interface AcademicSubject {
  topic: string;
  supervisor: EmployeeShortInfo | null;
  internshipType: InternshipType | null;
}

export interface WorkingHours {
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  sum?: number;
}

export interface HolidayEntitlement {
  remainingHolidays: number;
  annualHolidays: number;
}

export interface ZepAbsence {
  from: string | Date;
  to: string | Date;
  reason: string;
  hours: number | null;
  approved: boolean;
  halfDay: boolean;
}
export interface Absences {
  maternityLeaveAbsences: MaternityLeaveAbsence[];
  parentalLeaveAbsences: ParentalLeaveAbsence[];
  sabbaticalLeaveAbsences: SabbaticalLeaveAbsence[];
  zepAbsences: ZepAbsences[];
}

export interface Absence {
  id: string;
  start: string;
  end: string;
}

export interface ZepAbsences {
  from: string | Date;
  to: string | Date;
  reason: string;
  hours: number | null;
  approved: boolean;
  halfDay: boolean;
}

export interface MaternityLeaveAbsence extends Absence {
  birthdate: string | null;
  expectedBirthdate: string;
  leaveLengthAfterBirth: LeaveLengthAfterBirth;
}

export type ParentalLeaveAbsence = Absence;

export interface SabbaticalLeaveAbsenceSavetime {
  id: string | null;
  absenceId: string | null;
  period: string;
  savePercentage: number;
}

export interface SabbaticalLeaveAbsenceOfftime {
  id: string | null;
  absenceId: string | null;
  period: string;
  payoutPercentage: number;
}

export interface SabbaticalLeaveAbsence extends Absence {
  savingStart: string | null;
  documentLink: string | null;
  savetimes: SabbaticalLeaveAbsenceSavetime[];
  offtimes: SabbaticalLeaveAbsenceOfftime[];
}

export interface Holiday {
  entitlementBeginningOfTheYear: number;
  remaining: number;
  approved: number;
  balanced: number;
}

export interface SalaryAdjustment {
  salary: Salary;
  from: string | Date;
}

export interface EmployeeSalaryAdjustment extends SalaryAdjustment {
  employee: EmployeeShortInfo;
  processedByPayroll: boolean;
  deleted: boolean;
  fte: number;
  employmentStatus: EmploymentStatus;
  context: SalaryAdjustmentContext;
  createdAt: Date;
}

export enum SalaryAdjustmentContext {
  SALARY_ADJUSTMENT,
  SALARY_ADJUSTMENT_DELETED,
  ONBOARDING,
  REONBOARDING,
  EMPLOYMENT_STATUS_CHANGED,
  WORKING_HOURS_CHANGED,
}

export const SalaryAdjustmentContextDisplayName = {
  [SalaryAdjustmentContext.SALARY_ADJUSTMENT]: 'Gehaltsänderung',
  [SalaryAdjustmentContext.SALARY_ADJUSTMENT_DELETED]: 'Gehaltsänderung',
  [SalaryAdjustmentContext.ONBOARDING]: 'Onboarding',
  [SalaryAdjustmentContext.REONBOARDING]: 'Re-Onboarding',
  [SalaryAdjustmentContext.EMPLOYMENT_STATUS_CHANGED]: 'Anstellungswechsel',
  [SalaryAdjustmentContext.WORKING_HOURS_CHANGED]: 'Arbeitszeitenänderung',
};
