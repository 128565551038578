import styled, { css } from 'styled-components';
import { white } from 'shared/constants/colors';
import { ScrollFilter } from 'shared/components/TableHeaderElementWrapper/TableHeaderElementWrapper.styled';

interface TableHeadSpaceProps {
  $minWidth?: string;
}
export const TableHeadSpace = styled.div<TableHeadSpaceProps>`
  min-width: 2.5rem;
  ${(props: TableHeadSpaceProps) =>
    props.$minWidth &&
    css`
      min-width: ${props.$minWidth};
    `}
  background: ${white};
  top: 0;
  border-bottom: 3px solid rgb(223, 223, 224);
  height: 3.875rem;
  padding: 1.875rem 0 0.25rem 0;
`;

export const DatepickerHeaderWrapper = styled(ScrollFilter)`
  padding: 0.5rem 2rem 1.5rem;
  width: 12rem;
  > ino-datepicker {
    margin: 0 0 1rem 0;
  }
`;
