import * as React from 'react';
import { useEffect, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { Manager, Popper, Reference } from 'react-popper';
import styled from 'styled-components';
import { InoInput } from 'shared/InovexElements';
import {
  BigSortWrapper,
  Button,
  FilterWrapper,
  HeaderElementWrapper,
  HeaderFilterContainer,
  OffboardingDateSortWrapper,
  SortButtonContainer,
  SortOptionLabel,
  SortWrapper,
  TableHeaderLabel,
} from './TableHeaderElementWrapper.styled';
import { CustomInoIcon } from 'shared/style/CustomInoIcon';

interface TableHeaderElementWrapperProps {
  label?: string;
  onSearch?: (searchTerm: string) => void;
  searchTerm?: string;
  interactive?: boolean;
}

const TableHeaderElementWrapper: React.FunctionComponent<React.PropsWithChildren<TableHeaderElementWrapperProps>> = ({
  label,
  onSearch,
  searchTerm,
  interactive = true,
  children,
}) => {
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const headerFilterContainer = React.useRef<HTMLDivElement>(null);
  const header = React.useRef<HTMLDivElement>(null);

  const masterDetail = document.getElementById('table-container');
  const employeeOverview = document.getElementById('employee-consumer');
  const offboardingOverview = document.getElementById('offboarding-consumer');
  const oldScrollValue = useRef<number>();

  const handleClickOutside = (event: MouseEvent) => {
    if (
      headerFilterContainer.current &&
      !headerFilterContainer.current.contains(event.target as Node) &&
      header.current &&
      !header.current.contains(event.target as Node) &&
      ((employeeOverview && employeeOverview.childNodes[0].contains(event.target as Node)) ||
        (offboardingOverview && offboardingOverview.childNodes[0].contains(event.target as Node)))
    ) {
      setIsFilterOpen(false);
    }
  };

  const handleScroll = () => {
    if (masterDetail && masterDetail.scrollLeft !== oldScrollValue.current) {
      oldScrollValue.current = masterDetail.scrollLeft;
      setIsFilterOpen(false);
    }
  };

  useEffect(() => {
    if (masterDetail) {
      oldScrollValue.current = masterDetail.scrollLeft;
      masterDetail.addEventListener('scroll', handleScroll);
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      if (masterDetail) {
        masterDetail.removeEventListener('scroll', handleScroll);
      }
    };
  });

  const showSearch = onSearch && (isFilterOpen || searchTerm);
  return (
    <div ref={header}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <HeaderElementWrapper
              ref={ref}
              $interactive={interactive}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen);
              }}
            >
              <IconButton
                interactive={interactive}
                inoIcon="options"
                size="1.063rem"
                onClick={() => setIsFilterOpen(true)}
              />
              <TableHeaderLabel $showLabel={!showSearch}>{label}</TableHeaderLabel>
              {showSearch && (
                <SearchInput
                  label={label || ''}
                  value={searchTerm || ''}
                  onInput={(inputValue) => {
                    if (onSearch) {
                      onSearch(inputValue);
                    }
                  }}
                />
              )}
            </HeaderElementWrapper>
          )}
        </Reference>

        {isFilterOpen &&
          interactive &&
          ReactDOM.createPortal(
            <div ref={headerFilterContainer}>
              <Popper
                placement="bottom-start"
                modifiers={{
                  preventOverflow: {
                    boundariesElement: document.getElementById('table-container')!,
                  },
                }}
              >
                {({ ref, style, placement }) => (
                  <HeaderFilterContainer ref={ref} style={style} data-placement={placement}>
                    <FilterWrapper ref={ref}>{children}</FilterWrapper>
                  </HeaderFilterContainer>
                )}
              </Popper>
            </div>,
            document.querySelector('#tooltip')!
          )}
      </Manager>
    </div>
  );
};

export { TableHeaderElementWrapper };

/**
 *  Filter card with sort controls
 */
interface SortCardProps {
  onSortUpdate: (descending: boolean) => void;
  alphabetic?: boolean;
}

const InnerSortCard = ({ onSortUpdate, alphabetic }: SortCardProps) => (
  <React.Fragment>
    <SortOptionLabel>Sortierung</SortOptionLabel>
    <SortButtonContainer>
      <IconButton inoIcon={alphabetic ? 'sort_za' : 'sort_up'} size={'1.5rem'} onClick={() => onSortUpdate(true)} />
      <IconButton inoIcon={alphabetic ? 'sort_az' : 'sort_down'} size={'1.5rem'} onClick={() => onSortUpdate(false)} />
    </SortButtonContainer>
  </React.Fragment>
);

export const SortCard = ({ onSortUpdate, alphabetic }: SortCardProps) => (
  <SortWrapper>
    <InnerSortCard alphabetic={alphabetic} onSortUpdate={onSortUpdate} />
  </SortWrapper>
);

export const BigSortCard = ({ onSortUpdate, alphabetic }: SortCardProps) => (
  <BigSortWrapper>
    <InnerSortCard alphabetic={alphabetic} onSortUpdate={onSortUpdate} />
  </BigSortWrapper>
);

export const OffboardingSortCard = ({ onSortUpdate, alphabetic }: SortCardProps) => (
  <OffboardingDateSortWrapper>
    <InnerSortCard alphabetic={alphabetic} onSortUpdate={onSortUpdate} />
  </OffboardingDateSortWrapper>
);

interface IconButtonProps {
  inoIcon: string;
  interactive?: boolean;
  onClick: () => void;
  size?: string;
}

export const IconButton = ({ onClick, inoIcon, interactive = true, size = '1rem' }: IconButtonProps) => (
  <Button type="button" onClick={onClick} $interactive={interactive}>
    <CustomInoIcon icon={inoIcon} size={size} hoverEffect={interactive} />
  </Button>
);

/**
 * Text Search input field
 */

interface SearchInputProps {
  label: string;
  onInput: (value: string) => void;
  onFocus?: () => void;
  value: string;
  className?: string;
}

export const SearchInput = styled((props: SearchInputProps) => (
  <InoInput
    onValueChange={(event: CustomEvent<string>) => {
      props.onInput(event.detail);
    }}
    class={props.className}
    label={props.label}
    autoFocus
    onInoFocus={props.onFocus}
    value={props.value}
  />
))`
  display: inline-block;
  position: absolute;
  left: 20px;
  bottom: 0.8rem;
`;
