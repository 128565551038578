import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Logo } from 'components/Logo';

interface LoadingSpinnerProps {
  className?: string;
}

const Spinner: React.FunctionComponent<React.PropsWithChildren<LoadingSpinnerProps>> = (props) => <LoadingSpinnerImage type="inovex" className={props.className} />;

const logoSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingSpinnerImage = styled(Logo)`
  animation: ${logoSpin} infinite 5s linear;
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  margin: 0;
`;

const LoadingSpinner: React.FunctionComponent<React.PropsWithChildren<LoadingSpinnerProps>> = React.memo(() => <StyledSpinner />);

export { LoadingSpinner };
