import moment from 'moment';
import {
  Absences,
  Contract,
  Employee,
  EmploymentPeriod,
  WorkingHours,
  ZepAbsences,
} from 'shared/types/interfaces/Employee';
import { ApiEmployee, ApiEmploymentPeriod } from 'shared/types/interfaces/Api';
import { isCurrentPeriod } from './date-utils';
import { getClosestEmploymentPeriod } from './utils';

const parseEmployee = (employee: ApiEmployee, teams?: object): Employee => {
  const closestPeriod = getClosestEmploymentPeriod(employee.employmentPeriods, new Date());
  const contracts = employee.contracts;
  const closestContract = getContractByEmploymentPeriod(contracts, closestPeriod);

  let currentProbationEnd: Date | null | string = null;
  if (closestContract && moment(closestPeriod.probationEnd).isSame(closestContract.from, 'day')) {
    currentProbationEnd = null;
  } else if (!closestPeriod.probationEnd) {
    currentProbationEnd = '';
  } else {
    currentProbationEnd = new Date(closestPeriod.probationEnd);
  }

  return {
    ...employee,
    firstPeriod: employee.employmentPeriods[0],
    lastPeriod: employee.employmentPeriods[employee.employmentPeriods.length - 1],
    closestPeriod: {
      ...closestPeriod,
      workingHours: {
        ...closestPeriod.workingHours,
        sum: calculateSum(closestPeriod.workingHours),
      },
      probationEnd: currentProbationEnd,
    },
    closestContract,
    contracts: employee.contracts,
    birthday: new Date(employee.birthday),
    employmentPeriods: parseEmploymentPeriods(employee.employmentPeriods, teams),
    absence: getAbsence(employee.absences),
    absences: employee.absences,
    holiday: employee.holiday,
    activeOffboarding: employee.activeOffboarding,
  };
};

const parseEmployees = (employees: ApiEmployee[], teams?: object): Employee[] =>
  employees.map((employee) => parseEmployee(employee, teams));

const parseEmploymentPeriods = (employmentPeriods: ApiEmploymentPeriod[], teams?: object): EmploymentPeriod[] =>
  employmentPeriods
    .map((employmentPeriod) => parseEmploymentPeriod(employmentPeriod, teams))
    .sort((period1, period2) => moment(period1.from).diff(moment(period2.from)));

const parseEmploymentPeriod = (employmentPeriod: ApiEmploymentPeriod, teams?: object): EmploymentPeriod => {
  const parsedEmploymentPeriod = {
    ...employmentPeriod,
    from: new Date(employmentPeriod.from),
    to: employmentPeriod.to && new Date(employmentPeriod.to),
  };
  if (parsedEmploymentPeriod.team && parsedEmploymentPeriod.team.name) {
    parsedEmploymentPeriod.team.name = parsedEmploymentPeriod.team.name.replace('org-', '');
  }
  return parsedEmploymentPeriod;
};

const calculateSum = (workingHours: WorkingHours) => {
  const days = Object.keys(workingHours);
  return days.reduce((sum: number, day: string) => {
    const workingHoursOfDay = workingHours[day as keyof WorkingHours];
    return sum + (workingHoursOfDay ? parseFloat(workingHoursOfDay.toString()) : 0);
  }, 0);
};

const getContractByEmploymentPeriodId = (contracts: Contract[], employmentPeriodId: string): Contract | undefined =>
  contracts.flatMap((c) => c).find((c) => c.employmentPeriodsIds?.includes(employmentPeriodId));

const getContractByEmploymentPeriod = (contracts: Contract[], employmentPeriod: EmploymentPeriod): Contract => {
  const employmentPeriodFrom = moment(employmentPeriod.from);
  const employmentPeriodTo = employmentPeriod.to ? moment(employmentPeriod.to) : null;

  const firstContract = contracts[0];

  if (contracts.length === 1) {
    return firstContract;
  }

  for (const contract of contracts) {
    const contractFrom = moment(contract.from);
    const contractTo = contract.to ? moment(contract.to) : null;

    if (employmentPeriodFrom.isSame(contractFrom)) {
      return contract;
    }

    if (employmentPeriodFrom.isAfter(contractFrom)) {
      if (employmentPeriodTo == null && contractTo == null) {
        return contract;
      }

      if (employmentPeriodTo != null && contractTo != null) {
        if (employmentPeriodTo.isSameOrBefore(contractTo)) {
          return contract;
        }
      }

      if (employmentPeriodTo != null && contractTo == null) {
        return contract;
      }
    }
  }

  return firstContract;
};

const getAbsence = (absences: Absences): ZepAbsences | undefined =>
  absences?.zepAbsences?.find((absence) => isCurrentPeriod(absence.from as string, absence.to as string));

export { parseEmployee, parseEmployees, getContractByEmploymentPeriod, getContractByEmploymentPeriodId };
