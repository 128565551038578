import { config } from 'config';
import moment from 'moment';

export enum PICKER_DATE_TYPES {
  date = 'd.m.Y',
  month = 'm.Y',
}

export enum HELPER_TEXT_DATE_TYPES {
  date = 'TT.MM.JJJJ',
  datetime = 'TT.MM.JJJJ mm:ss',
  month = 'MM.JJJJ',
}

export enum UTC_DATE_TYPES {
  date = 'YYYY-MM-DD',
  datetime = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]',
  month = 'YYYY-MM',
}

export enum LOCAL_DATE_TYPES {
  date = 'DD.MM.YYYY',
  datetime = 'DD.MM.YYYY HH:mm',
  month = 'MM.YYYY',
  us_month = 'YYYY-MM',
}

interface DateFormats {
  utc: UTC_DATE_TYPES;
  local: LOCAL_DATE_TYPES;
  datePicker: PICKER_DATE_TYPES;
  helperText: HELPER_TEXT_DATE_TYPES;
}

export const fullFormat: DateFormats = {
  utc: UTC_DATE_TYPES.date,
  local: LOCAL_DATE_TYPES.date,
  datePicker: PICKER_DATE_TYPES.date,
  helperText: HELPER_TEXT_DATE_TYPES.date,
};

export const fullDatetimeFormat: DateFormats = {
  utc: UTC_DATE_TYPES.datetime,
  local: LOCAL_DATE_TYPES.datetime,
  datePicker: PICKER_DATE_TYPES.date,
  helperText: HELPER_TEXT_DATE_TYPES.datetime,
};

export const monthFormats: DateFormats = {
  utc: UTC_DATE_TYPES.month,
  local: LOCAL_DATE_TYPES.month,
  datePicker: PICKER_DATE_TYPES.month,
  helperText: HELPER_TEXT_DATE_TYPES.month,
};

export type DateType = 'month' | 'date';

export const formatHour = (hour: number) => formatDecimal(hour, 2);

export const formatDecimal = (decimal: number, fractionDigits: number) =>
  new Intl.NumberFormat(config.lang, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(decimal);

/**
 *
 * @param date
 * @param format
 * Format dates to local format as expected by the Frontend.
 * Date Objects are transformed using toMoment format util.
 */
export const formatDateInput = (date: string, formats: DateFormats) =>
  // Manual input (temporary date formats on typing: e.g. DD.MM.YYY) shouldn't be formatted
  isDateFormatValid(date, formats.utc) ? moment(date, formats.utc, true).format(formats.local) : date;

/**
 *
 * @param date
 * @param format
 * Format dates to utc format as expected by the Backend.
 */
export const formatDateOutput = (date: string, formats?: DateFormats) => {
  if (!formats) formats = fullFormat;
  // Manual input (temporary date formats on typing: e.g. DD.MM.YYY) datestrings shouldn't be formatted
  return isDateFormatValid(date, formats.local) ? moment(date, formats.local, true).format(fullFormat.utc) : date;
};

/**
 *
 * @param date
 * @param formatType
 * Does Date string apply to the prefered format type
 */
export const isDateFormatValid = (date: string | Date, format: LOCAL_DATE_TYPES | UTC_DATE_TYPES) => {
  if (moment(date, format, true).isValid()) {
    return true;
  } else {
    return false;
  }
};

export const toMoment = (date: string | Date | null, format?: LOCAL_DATE_TYPES | UTC_DATE_TYPES): moment.Moment =>
  format ? moment(date, format, true) : moment(date);

export const isDateObject = <T>(date: T): boolean => date instanceof Date;
