import styled, { css } from 'styled-components';
import { boxShadow, greyLight, primary2, textLight, textMedium, white } from 'shared/constants/colors';

export const StickyProp = styled.td<{
  $stickyLeft?: string;
  $stickyRight?: string;
}>`
  border-bottom: ${greyLight} solid 0.0625rem;
  position: sticky;
  font-size: 0.875rem;
  color: ${textMedium};
  letter-spacing: 0;
  text-align: left;
  z-index: 10;
  box-sizing: border-box;
  background-color: ${white};
  left: 0;
  box-shadow: 0 3px 20px 0 ${boxShadow};
  ${(props) =>
    props.$stickyLeft &&
    css`
      left: ${props.$stickyLeft} !important;
    `};
  ${(props) =>
    props.$stickyRight &&
    css`
      left: ${props.$stickyRight} !important;
    `};
  > a {
    color: ${textLight};
    text-decoration: inherit;
    &:hover {
      color: ${primary2};
      cursor: pointer;
    }
  }
`;

export const TextLink = styled.span`
  color: ${textLight};
  text-decoration: inherit;
  &:hover {
    color: ${primary2};
    cursor: pointer;
  }
`;

export const StickyPropStatus = styled(StickyProp)`
  left: 2.25rem;
`;

export const StickyPropName = styled(StickyProp)``;

export const Prop = styled.td<{
  $alignRight?: boolean;
  $maxWidth?: string;
  $minWidth?: string;
}>`
  border-bottom: ${greyLight} solid 0.0625rem;
  font-size: 0.875rem;
  color: ${textMedium};
  letter-spacing: 0;
  text-align: left;

  ${(props) =>
    props.$alignRight &&
    css`
      text-align: right;
    `}
  ${(props) =>
    props.$maxWidth &&
    css`
      max-width: ${props.$maxWidth};
    `}
  ${(props) =>
    props.$minWidth &&
    css`
      max-width: ${props.$maxWidth};
    `}
  text-overflow: ellipsis;
  > a {
    color: ${textLight};
    text-decoration: inherit;
    &:hover {
      color: ${primary2};
      cursor: pointer;
    }
  }
`;

export const ExpandableProp = styled(Prop)<{
  $expandedMaxWidth?: string;
  $alignRight?: boolean;
  $maxWidth?: string;
  $minWidth?: string;
}>`
  overflow: hidden;
  &:hover {
    ${(props) =>
      props.$expandedMaxWidth &&
      css`
        max-width: ${props.$expandedMaxWidth};
      `}
  }
`;

export const StatusImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.25rem;
`;
