import { SalaryPeriod } from 'shared/types/enums/SalaryPeriod';
import { getClosestEmploymentPeriod, getEmploymentPeriodByDate } from 'shared/utils/utils';
import { getContractByEmploymentPeriodId } from 'shared/utils/parseEmployee';
import { Employee, Salary } from 'shared/types/interfaces/Employee';
import _ from 'lodash';
import { toMoment } from './format';

export const currencyFormatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});
const percentFormatter = new Intl.NumberFormat('de-DE', {
  style: 'percent',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const formatPercent = (value: number) => percentFormatter.format(value);

export const calculateSalaryPerMonth = (salaryFullTimePerYear: number | ''): string =>
  salaryToString(parseFloat(salaryFullTimePerYear.toString()) / 12);

export const calculateSalaryPerYear = (salaryFullTimePerMonth: number | ''): string =>
  salaryToString(parseFloat(salaryFullTimePerMonth.toString()) * 12);

export const salaryToString = (salary: number): string => currencyFormatter.format(salary);

export const calculatePercentageDifference = (oldSalary: number, newSalary: number): string => {
  if (!oldSalary) {
    return '';
  }
  const percentageDiff = oldSalary !== 0 ? (newSalary - oldSalary) / oldSalary : -1;
  const sign = percentageDiff > 0 ? '+' : '';
  return sign + percentFormatter.format(percentageDiff);
};

export const getSalaryPeriodText = (salaryPeriod: SalaryPeriod): string => {
  switch (salaryPeriod) {
    case SalaryPeriod.HOUR:
      return '/ Stunde';
    case SalaryPeriod.MONTH:
      return '/ Monat';
    case SalaryPeriod.YEAR:
      return '/ Jahr';
    default:
      return '';
  }
};

export const calculateSalaryPerWorkingHours = (salary: number, workingHoursSum: number) =>
  salary * (workingHoursSum / 40);

export const isSameContract = (date1: Date | string, date2: Date | string, employee: Employee): boolean => {
  const ep1 = getEmploymentPeriodByDate(employee.employmentPeriods, date1);
  const ep2 = getEmploymentPeriodByDate(employee.employmentPeriods, date2);

  if (!ep1 || !ep2) {
    return false;
  }
  const contract = getContractByEmploymentPeriodId(employee.contracts, ep1.id);

  return !!contract?.employmentPeriodsIds?.includes(ep2.id);
};

export const getClosestSalary = (employee: Employee, date: Date): Salary => {
  const salaryAdjustments = _.sortBy(
    employee.salaryAdjustments?.filter((s) => s !== null),
    'from'
  ).reverse();

  const closestSalaryAdjustment = salaryAdjustments.filter(
    (salaryAdjustment) => !toMoment(salaryAdjustment?.from).isAfter(date)
  )[0];

  let closestSalary = closestSalaryAdjustment?.salary;
  if (!closestSalary) {
    const closestEmploymentPeriod = getClosestEmploymentPeriod(employee.employmentPeriods, date);
    closestSalary = _.sortBy(
      salaryAdjustments.filter(
        (salaryAdjustment) =>
          toMoment(salaryAdjustment.from).isAfter(date) && closestEmploymentPeriod?.employmentStatus?.id
      ),
      'from'
    ).map((salaryAdjustment) => salaryAdjustment.salary)[0];
  }

  return closestSalary;
};

export const getSalaryPayoutAtDate = (employee: Employee, date: Date): Salary | null => {
  const closestSalary = getClosestSalary(employee, date);
  if (!closestSalary) {
    return null;
  }

  const closestSalaryPartTime: Salary = {
    ...closestSalary,
    value: '',
  };

  const ep = getClosestEmploymentPeriod(employee.employmentPeriods, date);
  const workingHoursSum =
    ep.workingHours.monday +
    ep.workingHours.tuesday +
    ep.workingHours.wednesday +
    ep.workingHours.thursday +
    ep.workingHours.friday;

  closestSalaryPartTime.value = calculateSalaryPerWorkingHours(closestSalary.value as number, workingHoursSum);

  const showClosestSalary =
    closestSalary &&
    ((ep.employmentStatus?.isWorkingStudent &&
      SalaryPeriod[closestSalary.period as SalaryPeriod] === SalaryPeriod.HOUR) ||
      (!ep.employmentStatus?.isWorkingStudent &&
        SalaryPeriod[closestSalary.period as SalaryPeriod] === SalaryPeriod.YEAR));

  if (showClosestSalary) {
    return closestSalaryPartTime.value ? closestSalaryPartTime : closestSalary;
  }

  return null;
};
