import * as React from 'react';
import styled from 'styled-components';
import { headingDecorator, textDark, textMedium } from 'shared/constants/colors';

// Headings from style guide
const HeadingPrimary = styled.h1`
  color: ${textDark};
  font-size: 0.9375rem;
  font-weight: 400;
  text-transform: uppercase;
`;

const H2 = styled.h2`
  color: ${textMedium};
  font-size: 0.8125rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
`;

const HeadingSecondary = styled(({ label, isSticky, hasDecorator, className }: {label?: string, isSticky?: boolean, hasDecorator?: boolean, className?: string}) => (
  <H2 className={className}>{label}</H2>
))`
  ${({ hasDecorator }) =>
    hasDecorator &&
    `
      display: flex;
      width: 100%;
      justify-content: center;
      background-color: white;
      align-items: center;
      text-align: center;
      &:after {
        content: '';
        border-top: 10px solid;
        border-color: ${headingDecorator};
        border-radius: 2px;
        margin: auto 0 auto 0.625rem;
        flex: 1 0 20px;
      }
    `}
  ${({ isSticky }) =>
    isSticky &&
    `
      z-index: 1;
      position: sticky;
      top: 0;
      padding: 0.75rem 0;
      z-index: 1;
  `}
`;

export { HeadingPrimary, HeadingSecondary };
