import { useCallback, useLayoutEffect, useRef, useEffect } from 'react';

/*
 * Custom hook to provide reorder animation
 *
 */
export const useReorderAnimation = <T>(
  listData: T | null,
  listRef: React.RefObject<HTMLElement>,
  scrollContainerRef: React.RefObject<HTMLElement>
) => {
  const listItemPositions = useRef<{ [key: string]: DOMRect }>({});
  const firstPaint = useRef(true);
  const scrollYPos = useRef<number>(0);

  const setListItemPositionings = useCallback(
    (list: HTMLElement, animationCb?: (child: HTMLElement, prevRect: DOMRect, newRect: DOMRect) => void) => {
      const children = [...Array.from(list.children)] as HTMLElement[];

      children.forEach((child) => {
        const key = child.dataset.key;
        const newRect = child.getBoundingClientRect(); // new position of the item after reorder

        if (!key) return;

        if (key in listItemPositions.current && !firstPaint.current && animationCb) {
          const prevRect = listItemPositions.current[key]; // previous position of the item
          animationCb(child, prevRect, newRect);
        }

        listItemPositions.current[child.dataset.key!] = newRect;
      });
    },
    [listItemPositions]
  );

  const handleOnScroll = useCallback(
    (e: Event) => {
      if (scrollContainerRef.current === null) return;
      const scrollContainer = e.currentTarget as HTMLDivElement;

      if (scrollContainer && scrollContainer.id === 'table-container') {
        if (listRef.current === null) return;
        const list = listRef.current;
        setListItemPositionings(list);
        scrollYPos.current = scrollContainer.scrollTop;
      }
    },
    [scrollYPos, listRef]
  );

  useEffect(() => {
    if (scrollContainerRef.current === null) return;
    scrollYPos.current = scrollContainerRef.current.scrollTop;
    scrollContainerRef.current?.addEventListener('scroll', handleOnScroll);

    return () => {
      scrollContainerRef.current?.removeEventListener('scroll', handleOnScroll);
    };
  }, [scrollContainerRef]);

  // Animate list item positioning rendering on paint
  useLayoutEffect(() => {
    if (listRef.current === null) return;
    const list = listRef.current;

    setListItemPositionings(list, (child, prevRect, newRect) => {
      const positionDiff = getPositionDiff(prevRect, newRect);

      if (positionChanged(positionDiff)) {
        moveToPrevPosition(positionDiff, child);

        requestAnimationFrame(() => {
          animatePositionChange(child);
        });
      }
    });

    firstPaint.current = false;
  }, [listData]);
};

const moveToPrevPosition = (diff: Rect, el: HTMLElement) => {
  el.style.transform = `translate(${diff.left}px, ${diff.top}px)`;
  el.style.transition = `transform 0s`;
};

const animatePositionChange = (elem: HTMLElement) => {
  elem.style.transform = ``;
  elem.style.transition = `transform 300ms ease`;
};

type Rect = Pick<DOMRect, 'top' | 'left'>;

const getPositionDiff = (prev: Rect, next: Rect) => ({
  top: prev.top - next.top,
  left: prev.left - next.left,
});

const positionChanged = (delta: Rect) => delta.left !== 0 || delta.top !== 0;
