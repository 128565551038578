import { TextComment } from 'components/Typography';
import styled from 'styled-components';

export const ErrorPageWrapper = styled.div`
  width: 100%;
  text-align: center;
  height: 100%;
  overflow: hidden;
`;

export const ErrorImg = styled.img`
  display: block;
  margin: 8% auto 0 auto;
  max-width: 600px;
`;

export const ErrorMessage = styled(TextComment)`
  text-align: center;
  padding: 0.2rem 1rem;
`;
