import * as React from 'react';
import { InoTooltip } from '@inovex.de/elements-react';
import { iconGreyMedium } from 'shared/constants/colors';
import { CustomInoIcon } from 'shared/style/CustomInoIcon';
import { formatDateToString, getMonthNameByValue } from 'shared/utils/utils';
import {
  DetailAttribute,
  DetailAttributeIcon,
  DetailAttributeText,
  ListDetail,
  AbsenceWrapper,
  ClipboardText,
} from './AbsencesDetail';
import { AbsenceDashboardAbsenceItem } from 'shared/types/interfaces/Absences';
import { AbsenceDetailHeader } from './AbsenceDetailHeader';
import { SabbaticalLeaveAbsence } from 'shared/types/interfaces/Employee';
import _ from 'lodash';
import { LOCAL_DATE_TYPES, toMoment } from 'shared/utils/format';
import { getSalaryPayoutAtDate } from 'shared/utils/salary-utils';
import styled from 'styled-components';
import { parseEmployee } from 'shared/utils/parseEmployee';
import { InoHeader } from 'shared/components/InoHeader';

interface SabbaticalLeaveProps {
  id: number;
  start: string;
  end: string;
  startSaving: string;
  documentLink: string;
  absence: AbsenceDashboardAbsenceItem;
}

const DetailUlWrapper = styled.ul`
  margin: 0px;
  padding: 0px;

  li {
    list-style: none;
    margin-bottom: 15px;
  }
`;

export const SabbaticalLeaveAbsenceDetails: React.FunctionComponent<React.PropsWithChildren<SabbaticalLeaveProps>> = (
  props
) => {
  const details = props.absence.details as SabbaticalLeaveAbsence;
  const currentEmployee = parseEmployee(props.absence.employee);

  const periodToDate = (period: string) => toMoment(period, LOCAL_DATE_TYPES.us_month).toDate();
  const currentMonthlySalary = (currentYearlySalary: number) => currentYearlySalary / 12;
  const calculateNewSalarySaving = (currentYearlySalary: number, savingPercentage: number) =>
    (
      currentMonthlySalary(currentYearlySalary) -
      currentMonthlySalary(currentYearlySalary) * (savingPercentage / 100)
    ).toFixed(2);
  const calculateNewSalaryOfftime = (currentYearlySalary: number, payoutPercentage: number) =>
    (currentMonthlySalary(currentYearlySalary) * (payoutPercentage / 100)).toFixed(2);

  return (
    <AbsenceWrapper>
      <InoHeader $firstHeading>Auszeit</InoHeader>
      <AbsenceDetailHeader start={props.absence.start} end={props.absence.end}></AbsenceDetailHeader>
      <ListDetail>
        <li id={'li-start-saving-' + props.id}>
          <DetailAttribute>
            <DetailAttributeText>
              {formatDateToString(props.startSaving) === '-' ? 'Keine Angabe' : formatDateToString(props.startSaving)}
            </DetailAttributeText>
            <DetailAttributeIcon>
              <CustomInoIcon
                src={process.env.PUBLIC_URL + '/icons/money-icon.svg'}
                color={iconGreyMedium}
                size={'1.2rem'}
              ></CustomInoIcon>
            </DetailAttributeIcon>
          </DetailAttribute>
        </li>
        <li className="contract" id={'li-contract-' + props.id}>
          <DetailAttribute>
            <DetailAttributeText>
              <a href={props.documentLink} target="_blank" rel="noreferrer">
                {props.documentLink || 'Keine Angabe'}
              </a>
            </DetailAttributeText>
            <DetailAttributeIcon>
              <CustomInoIcon
                size={'1.2rem'}
                src={process.env.PUBLIC_URL + '/icons/sign-document-icon.svg'}
                color={iconGreyMedium}
              ></CustomInoIcon>
            </DetailAttributeIcon>
          </DetailAttribute>
        </li>
        <InoTooltip for={'li-start-saving-' + props.id} placement="right" trigger="mouseenter" colorScheme={'light'}>
          {'Start der Ansparungsphase'}
        </InoTooltip>
        <InoTooltip for={'li-contract-' + props.id} placement="right" trigger="mouseenter" colorScheme={'light'}>
          {'Ergänzungsvertrag'}
        </InoTooltip>
      </ListDetail>
      <InoHeader>Ansparphase</InoHeader>
      <DetailUlWrapper>
        {details.savetimes?.map((savetime) => {
          const periodDate = periodToDate(savetime.period);
          const salaryAtSavingStart = getSalaryPayoutAtDate(currentEmployee, periodToDate(savetime.period))?.value;

          if (salaryAtSavingStart) {
            const payoutSalary = calculateNewSalarySaving(salaryAtSavingStart, savetime.savePercentage);

            return (
              <li key={savetime.id}>
                <b>
                  {getMonthNameByValue(periodDate.getMonth())} {periodDate.getFullYear()}
                </b>
                <br />
                Auszahlungsbetrag: <ClipboardText>{payoutSalary}</ClipboardText> EUR
                <br />
                Sparrate: {savetime.savePercentage}%
              </li>
            );
          } else {
            return (
              <li key={savetime.id}>
                <b>
                  {getMonthNameByValue(periodDate.getMonth())} {periodDate.getFullYear()}
                </b>
                <br />
                Auszahlungsbetrag: -
                <br />
                Sparrate: {savetime.savePercentage}%
              </li>
            );
          }
        })}
      </DetailUlWrapper>
      <InoHeader>Abwesenheit</InoHeader>
      <DetailUlWrapper>
        {details.offtimes?.map((offtime) => {
          const periodDate = periodToDate(offtime.period);
          const salaryAtSavingStart = getSalaryPayoutAtDate(currentEmployee, periodToDate(offtime.period))?.value;

          if (salaryAtSavingStart) {
            const payoutSalary = calculateNewSalaryOfftime(salaryAtSavingStart, offtime.payoutPercentage);

            return (
              <li key={offtime.id}>
                <b>
                  {getMonthNameByValue(periodDate.getMonth())} {periodDate.getFullYear()}
                </b>
                <br />
                Auszahlungsbetrag: <ClipboardText>{payoutSalary}</ClipboardText> EUR
                <br />
                Auszahlrate: {offtime.payoutPercentage}%
              </li>
            );
          } else {
            return (
              <li key={offtime.id}>
                <b>
                  {getMonthNameByValue(periodDate.getMonth())} {periodDate.getFullYear()}
                </b>
                <br />
                Auszahlungsbetrag: -
                <br />
                Auszahlrate: {offtime.payoutPercentage}%
              </li>
            );
          }
        })}
      </DetailUlWrapper>
    </AbsenceWrapper>
  );
};
