import { InoIcon, InoTooltip } from '@inovex.de/elements-react';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const CalculatedIcon = styled(InoIcon)`
  position: relative;
  top: 2px;
  left: -4px;
`;

interface CalculatedHelperProps {
  id: string;
  style?: CSSProperties;
}

export const CalculatedHelper = ({ id, style }: CalculatedHelperProps) => (
  <div style={style}>
    <CalculatedIcon id={`${id}-tooltip`} icon="info"></CalculatedIcon>
    <InoTooltip
      for={`${id}-tooltip`}
      placement={'left'}
      trigger={'mouseenter'}
      colorScheme={'light'}
    >{'Errechneter Wert'}</InoTooltip>
  </div>
);
