import * as React from 'react';
import styled from 'styled-components';
import { white } from 'shared/constants/colors';
import { RefAttributes } from 'react';

const Master = styled.div<{ columns: string; $noShadow?: boolean }>`
  box-sizing: border-box;
  display: grid;
  height: 100%;
  grid-template-columns: ${(props) => props.columns};
  > div {
    &:nth-child(even) {
      box-shadow: ${(props: { columns: string; $noShadow?: boolean }) =>
        props.$noShadow ? 'none' : `0 0 30px 0 rgba(0, 0, 0, 0.3)`};
    }
  }
`;

const Detail = styled.div`
  overflow: auto;
  background-color: ${white};
`;

export enum LayoutState {
  MASTER = 0,
  DETAIL = 1,
}

interface MasterDetailProps {
  size: string;
  children: React.ReactNode | React.ReactNode[];
  noShadow?: boolean;
}

export const MasterDetail: React.FunctionComponent<React.PropsWithChildren<MasterDetailProps>> = (props) => {
  const { children, noShadow, size } = props;
  const columns = Array.isArray(children) ? `${size} 1fr` : `1fr`;

  return (
    <Master columns={columns} $noShadow={noShadow}>
      {Array.isArray(children) ? children[0] : children}
      {Array.isArray(children) && <Detail id="masterdetail-detail">{children[1]}</Detail>}
    </Master>
  );
};
