import * as React from 'react';
import { FetchError } from 'apis/FetchError';
import { InoSnackbar } from 'shared/InovexElements';
import { ApiProps, SharedContext } from 'shared/types/interfaces/Api';
import { ApiContext } from './RequestManager';
import { User } from 'shared/types/interfaces/User';
import { isOnlyFinanceUser } from 'shared/utils/utils';

const errorMessage = 'Daten konnten nicht geladen werden';

// React Higher-Order Components in TypeScript
// https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb
// NOTE: WrappedProps must be passed as a replacement for ApiProps, because the compiler cannot resolve the types otherwise
// This is definitely a TypeScript issue.
/* TODO: this is just a temporarily solution, to fetch the detail view of an employee. Maybe we have to find a better one.
   The Change: <GenericData = null> is missing as props fpr WrappedProps */
interface WrappedProps {
  api: SharedContext;
  urlParam?: string;
}

// Omit Type allows us to remove the injected ApiProps from the props of the retured component, after they have been set.
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html#predefined-conditional-types
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

// API connector. Connects the passed component to the shared context needed to make API calles to the backend
const connectToApi = <Data, T extends ApiProps<Data> & { urlParam?: string }>(
  Component: React.ComponentType<React.PropsWithChildren<T>>,
  promiseGetterFn?: (api: SharedContext, urlParam?: string) => Promise<Data>
): React.FunctionComponent<React.PropsWithChildren<Omit<T, keyof ApiProps<Data>>>> => {
  interface WrappedComponentState {
    isLoading: boolean;
    data: Data | null;
    error: FetchError | null;
  }

  class WrappedComponent<K extends WrappedProps> extends React.PureComponent<K, WrappedComponentState> {
    public static contextType = ApiContext;
    public state = { isLoading: true, data: null, error: null };
    public context: any;
    private didUnmount = false;

    // Loads data that is needed before the component renders
    public componentDidMount() {
      this.executeGetterFn();
    }
    // Loads data that if urlParam is updated
    public componentDidUpdate(prevProps: WrappedProps) {
      if (prevProps.urlParam !== this.props.urlParam) {
        this.executeGetterFn();
      }
    }
    // blocks setState on unmounted components
    public componentWillUnmount() {
      this.didUnmount = true;
    }

    // Renders the wrapped component. Sets the props concerning the state of loaded data and passes the rest as is.
    public render() {
      const { ...props } = this.props as WrappedProps;
      const { ...state } = this.state as T;

      return (
        <React.Fragment>
          <Component {...props} {...state} refreshData={this.executeGetterFn} />
          {state.error && state.error.getResponse().status !== 403 && !state.isLoading && (
            <InoSnackbar
              type="error"
              timeout={-1}
              onHideEl={() =>
                this.setState({
                  ...this.state,
                  error: null,
                })
              }
            >
              {errorMessage}
            </InoSnackbar>
          )}
        </React.Fragment>
      );
    }

    private executeGetterFn = () => {
      if (promiseGetterFn) {
        this.setState({ isLoading: true });
        promiseGetterFn(this.props.api, this.props.urlParam)
          .then((data) => {
            if (!this.didUnmount) {
              this.setState({ data, isLoading: false, error: null });
            }
            return data;
          })
          .catch((error) => {
            const isAuthenticationError = () => error?.getResponse().status === 401;
            const setFullErrorPage = this.props.api.setFullErrorPage;
            const user: User = this.context.getUser();
            // navigations to detail pages:
            // Only HTTP 401 Errors and 403 Errors for finance employees should be handle as full error pages
            // For others HTTP 403 etc. should be handled on detail page
            if (isAuthenticationError() || (error?.getResponse().status === 403 && isOnlyFinanceUser(user))) {
              setFullErrorPage(error);
              return;
            }

            if (!this.didUnmount) this.setState({ error, isLoading: false });
          });
      }
    };
  }

  // Renders the component inside the consumer for the global context and injects the API Methods
  return (props) => (
    <ApiContext.Consumer>
      {(context: SharedContext) => <WrappedComponent api={context} {...props} />}
    </ApiContext.Consumer>
  );
};

export { connectToApi };
