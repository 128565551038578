import styled from 'styled-components';
import { error, primary3, textDark, textLight } from 'shared/constants/colors';

// Paragraphs from style guide

const Text = styled.p`
  color: ${textDark};
  font-size: 0.875rem;
  font-weight: 400;
`;

const TextLight = styled(Text)`
  color: ${textLight};
`;

const TextBold = styled(Text)`
  font-weight: bold;
`;

const TextError = styled(Text)`
  color: ${error};
  font-weight: 300;
  font-size: 0.875rem;
  font-style: italic;
`;

const TextComment = styled(TextLight)`
  font-style: italic;
  font-size: 0.875rem;
`;

const TextSummary = styled(Text)`
  letter-spacing: 0.075rem;
  font-size: 0.875rem;
`;

const TextPrimary = styled(Text)`
  letter-spacing: 0.075rem;
  font-size: 0.875rem;
  color: ${primary3};
`;

export { Text, TextComment, TextLight, TextBold, TextError, TextSummary, TextPrimary };
