import { InoCheckbox } from '@inovex.de/elements-react';
import { EmployeeShortInfo } from 'components/EmployeeShortInfo';

import * as React from 'react';
import { Link } from 'react-router-dom';
import { Prop, StickyProp } from 'shared/components/TableEntry/TableEntry.styled';
import { iconGreyLight } from 'shared/constants/colors';
import { SalaryText, SalaryWithCurrency } from 'shared/style/Salary.styled';
import { SalaryPeriod } from 'shared/types/enums/SalaryPeriod';
import {
  EmployeeSalaryAdjustment,
  SalaryAdjustmentContextDisplayName,
  SalaryAdjustmentContext,
} from 'shared/types/interfaces/Employee';
import {
  getSalaryPeriodText,
  salaryToString,
  calculateSalaryPerMonth,
  calculateSalaryPerYear,
  calculateSalaryPerWorkingHours,
} from 'shared/utils/salary-utils';
import { formatDateToString } from 'shared/utils/utils';
import styled from 'styled-components';
import { CalculatedHelper } from '../CalculatedHelper';
import _ from 'lodash';

interface SalaryTableRowProps {
  salary: EmployeeSalaryAdjustment;
  toggleProcessedByPayroll: (event: CustomEvent, salary: EmployeeSalaryAdjustment) => void;
}

const SalaryGridWrapper = styled.div`
  --ino-icon-color-primary: ${iconGreyLight};
  --ino-icon-width: 18px;
  display: grid;
  grid-template-columns: 18px 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'helper-row-1 content-row-1'
    'helper-row-2 content-row-2';
`;

export const SalaryTableRow: React.FunctionComponent<React.PropsWithChildren<SalaryTableRowProps>> = React.memo(
  (props) => {
    const { salary, toggleProcessedByPayroll } = props;
    const dataKey = `${salary.employee.id}-${salary.createdAt}`;
    const salaryAdjustmentContext = SalaryAdjustmentContext[salary.context] as unknown as SalaryAdjustmentContext;

    return (
      <tr data-key={dataKey} style={{ backgroundColor: salary.deleted ? 'rgba(255, 0, 0, 0.08)' : 'inherit' }}>
        <StickyProp $stickyLeft="0rem">
          <InoCheckbox
            data-cy="processedByPayroll"
            aria-label="Bereits erledigt"
            name="processedByPayroll"
            checked={salary.processedByPayroll}
            onCheckedChange={(event) => {
              toggleProcessedByPayroll(event, salary);
            }}
          ></InoCheckbox>
        </StickyProp>
        <StickyProp $stickyLeft="4rem">
          <EmployeeShortInfo employeeId={salary.employee.id} loading="lazy" />
        </StickyProp>
        <StickyProp $stickyLeft={'9rem'}>
          <Link to={`/employee/detail/${salary.employee.id}`}>
            {`${salary.employee.name.lastName}, ${salary.employee.name.firstName}`}
          </Link>
        </StickyProp>
        <Prop>{`${salary.employmentStatus?.name} - ${salary.employmentStatus?.detail}`}</Prop>
        <Prop>{formatDateToString(salary.from)}</Prop>
        <Prop>
          {SalaryPeriod[salary.salary.period as SalaryPeriod] === SalaryPeriod.YEAR && (
            <SalaryText>
              <SalaryGridWrapper>
                <SalaryWithCurrency
                  style={{ gridArea: 'content-row-1' }}
                  data-period-text={getSalaryPeriodText(SalaryPeriod.YEAR as SalaryPeriod)}
                >
                  {salaryToString(salary.salary.value as number)}
                </SalaryWithCurrency>
                <CalculatedHelper id={`${dataKey}-1`} style={{ gridArea: 'helper-row-2' }}></CalculatedHelper>
                <SalaryWithCurrency
                  style={{ gridArea: 'content-row-2' }}
                  data-period-text={getSalaryPeriodText(SalaryPeriod.MONTH as SalaryPeriod)}
                >
                  {calculateSalaryPerMonth(salary.salary.value as number)}
                </SalaryWithCurrency>
              </SalaryGridWrapper>
            </SalaryText>
          )}
          {SalaryPeriod[salary.salary.period as SalaryPeriod] === SalaryPeriod.MONTH && (
            <SalaryText>
              <SalaryGridWrapper>
                <CalculatedHelper id={`${dataKey}-1`} style={{ gridArea: 'helper-row-1' }}></CalculatedHelper>
                <SalaryWithCurrency
                  style={{ gridArea: 'content-row-1' }}
                  data-period-text={getSalaryPeriodText(SalaryPeriod.YEAR)}
                >
                  {calculateSalaryPerYear(salary.salary.value as number)}
                </SalaryWithCurrency>

                <SalaryWithCurrency
                  style={{ gridArea: 'content-row-2' }}
                  data-period-text={getSalaryPeriodText(SalaryPeriod.MONTH)}
                >
                  {salaryToString(salary.salary.value as number)}
                </SalaryWithCurrency>
              </SalaryGridWrapper>
            </SalaryText>
          )}
          {SalaryPeriod[salary.salary.period as SalaryPeriod] === SalaryPeriod.HOUR && (
            <SalaryText>
              <SalaryGridWrapper>
                <SalaryWithCurrency
                  style={{ gridArea: 'content-row-1', gridTemplateRows: '1fr' }}
                  data-period-text={getSalaryPeriodText(SalaryPeriod.HOUR)}
                >
                  {salaryToString(salary.salary.value as number)}
                </SalaryWithCurrency>
              </SalaryGridWrapper>
            </SalaryText>
          )}
        </Prop>
        <Prop>
          {salary.fte &&
          salary.fte < 1 &&
          (SalaryPeriod[salary.salary.period as SalaryPeriod] === SalaryPeriod.YEAR ||
            SalaryPeriod[salary.salary.period as SalaryPeriod] === SalaryPeriod.MONTH) ? (
            <SalaryText>
              <SalaryGridWrapper>
                {SalaryPeriod[salary.salary.period as SalaryPeriod] !== SalaryPeriod.YEAR && (
                  <CalculatedHelper id={`${dataKey}-2`} style={{ gridArea: 'helper-row-1' }}></CalculatedHelper>
                )}
                <SalaryWithCurrency
                  style={{ gridArea: 'content-row-1' }}
                  data-period-text={getSalaryPeriodText(SalaryPeriod.YEAR)}
                >
                  {SalaryPeriod[salary.salary.period as SalaryPeriod] === SalaryPeriod.YEAR
                    ? salaryToString(calculateSalaryPerWorkingHours(salary.salary.value as number, salary.fte * 40))
                    : calculateSalaryPerYear(
                        calculateSalaryPerWorkingHours(salary.salary.value as number, salary.fte * 40)
                      )}
                </SalaryWithCurrency>
                {SalaryPeriod[salary.salary.period as SalaryPeriod] !== SalaryPeriod.MONTH && (
                  <CalculatedHelper id={`${dataKey}-2`} style={{ gridArea: 'helper-row-2' }}></CalculatedHelper>
                )}
                <SalaryWithCurrency
                  style={{ gridArea: 'content-row-2' }}
                  data-period-text={getSalaryPeriodText(SalaryPeriod.MONTH)}
                >
                  {SalaryPeriod[salary.salary.period as SalaryPeriod] === SalaryPeriod.MONTH
                    ? salaryToString(calculateSalaryPerWorkingHours(salary.salary.value as number, salary.fte * 40))
                    : calculateSalaryPerMonth(
                        calculateSalaryPerWorkingHours(salary.salary.value as number, salary.fte * 40)
                      )}
                </SalaryWithCurrency>
              </SalaryGridWrapper>
            </SalaryText>
          ) : (
            '-'
          )}
        </Prop>
        <Prop>{+(salary.fte * 40).toFixed(2)}</Prop>
        <Prop>
          <SalaryGridWrapper>
            <span style={{ gridArea: 'content-row-1' }}>
              {SalaryAdjustmentContextDisplayName[salaryAdjustmentContext]}
            </span>
            <span style={{ gridArea: 'content-row-2' }}>
              {salary.deleted
                ? '(gelöscht)'
                : salaryAdjustmentContext === SalaryAdjustmentContext.SALARY_ADJUSTMENT_DELETED
                ? '(durch Löschung)'
                : ''}
            </span>
          </SalaryGridWrapper>
        </Prop>
        <Prop>{formatDateToString(salary.createdAt)}</Prop>
        <Prop></Prop>
      </tr>
    );
  }
);
