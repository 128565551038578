import * as React from 'react';
import { Team } from 'shared/types/interfaces/Employee';

export interface TeamContext {
  teams: { [key: string]: string };
}

const Ctxt = React.createContext<TeamContext | null>(null);

export const TeamProvider = Ctxt.Provider;

export const TeamConsumer = Ctxt.Consumer;

export const buildTeamName = (team: Team, teams?: { [key: string]: string } | undefined) =>
  teams && [null, undefined, 'inovex', 'inovex GmbH'].indexOf(teams[team.parentId!]) < 0
    ? teams[team.parentId!] + ' - ' + team.name
    : team.name;

export { Ctxt as teamContext };
