import { config } from 'config';
import { FetchError } from 'apis/FetchError';
import { ForbiddenError } from 'apis/ForbiddenError';
import { NotFoundError } from 'apis/NotFoundError';
import { ServerError } from 'apis/ServerError';
import { convertDates } from 'shared/utils/utils';
import { UnauthorizedError } from 'apis/UnauthorizedError';

interface PushToOptions {
  command?: string;
  method?: string;
}

const defaultPushToOptions: PushToOptions = {
  method: 'POST',
};

function getFromServer<T>(path: string, baseUrl = config.apiBaseUrl): Promise<T> {
  return call(baseUrl + path);
}

function pushToServer<T, K>(path: string, data: K, options?: PushToOptions): Promise<T> {
  return call(config.apiBaseUrl + path, {
    method: (options ? options.method : undefined) || defaultPushToOptions.method,
    headers: {
      'Content-Type': `application/${options && options.command ? `x.${options.command}+` : ''}json; charset=utf-8`,
      'Access-Control-Expose-Headers': 'Location',
    },

    body: data ? JSON.stringify(convertDates(data)) : undefined,
  });
}

const call = <T>(url: string, options?: RequestInit): Promise<T> =>
  fetch(url, { ...options, credentials: 'include' }).then((response: Response) => {
    if (response.status === 401) {
      window.location.assign(
        `${config.apiBaseUrl}/authentication?auth-success-url=${window.location.href}&auth-failure-url=${window.location.origin}/unauthorized`
      );
      return Promise.reject(new UnauthorizedError(response));
    } else if (response.status === 403) {
      return Promise.reject(new ForbiddenError(response));
    } else if (response.status === 404) {
      return Promise.reject(new NotFoundError(response));
    } else if (response.status >= 500) {
      return Promise.reject(new ServerError(response));
    } else if (response.status >= 300) {
      return Promise.reject(new FetchError('HTTP Status ' + response.status, response));
    } else if (response.status === 201) {
      const createdResourceLocation = response.headers.get('Location');
      if (createdResourceLocation) {
        const locationParts = createdResourceLocation.split('/');
        const uuid = locationParts[locationParts.length - 1];
        return Promise.resolve(uuid);
      }
      return Promise.resolve();
    } else if (response.status > 200) {
      return Promise.resolve();
    }
    return response.json();
  });

export { call, getFromServer, pushToServer, defaultPushToOptions };
