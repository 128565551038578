import styled from 'styled-components';
import { InoIcon } from '@inovex.de/elements-react';

export const CustomInoIcon = styled(InoIcon)<{
  color?: string;
  size?: string;
  rotation?: string;
  hoverEffect?: boolean;
}>`
  ${(props) =>
    props.color &&
    `
  --ino-icon-color-primary: ${props.color};
`}
  ${(props) =>
    props.size &&
    `
  --ino-icon-width: ${props.size};
  --ino-icon-height: ${props.size};
`}
  ${(props) =>
    props.rotation &&
    `
  transform: rotateY(0deg) rotate(${props.rotation});
`}
   ${(props) =>
    props.hoverEffect &&
    `
   transition: all 0.1s ease-in-out;
    &:hover {
    transform: scale(1.15);
    }
`}
`;
