import styled, { createGlobalStyle } from 'styled-components';

export const BottomMarginComponent = styled.div`
  margin-bottom: 2rem;
`;

export const GlobalStyle = createGlobalStyle`
  body {
    /*
     * Resolves https://github.com/inovex/elements/issues/25 
     */
    ino-select input {
      width: 90%;
    }
  }

  .layout-helper {
    min-height: 1em;
    min-width: 1em;
  }
`;
