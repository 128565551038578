import * as React from 'react';

import { absenceUrl, employeeUrl } from 'apis/api/url-constants';
import { connectToApi } from 'components/RequestManager';
import { ApiProps, SharedContext } from 'shared/types/interfaces/Api';
import {
  MonthOverviewHeaderRow,
  MonthOverviewTable,
  TableHeader,
} from 'shared/components/Overview/OverviewTable.styled';
import { TableHeadSpace } from 'shared/components/TableHeader/TableHeader.styled';
import { TableHeaderElementWrapper } from 'shared/components/TableHeaderElementWrapper';
import { toMoment } from 'shared/utils/format';
import _ from 'lodash';

import { mapActionToText } from './utils';
import { Prop, StickyProp } from 'shared/components/TableEntry/TableEntry.styled';
import { EmployeeShortInfo } from 'components/EmployeeShortInfo';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { formatDateToString, isPayrollUser } from 'shared/utils/utils';
import styled from 'styled-components';
import { iconGreyMedium } from 'shared/constants/colors';
import { CustomInoIcon } from 'shared/style/CustomInoIcon';
import { AbsenceDashboardAbsenceItem } from 'shared/types/interfaces/Absences';
import ErrorPages from 'components/ErrorPages';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { MasterDetail } from 'components/MasterDetail';
import { routes } from 'components/Application/routes';
import { TableContainer } from 'components/EmployeeOverview/Table/TableContainer';
import { LayoutState } from 'components/MasterDetail/MasterDetail';
import AbsenceDetail from 'components/AbsencesDetail/AbsencesDetail';
import { getMonthGroupHeaderText } from 'components/SalaryOverview/utils';

import { InoCheckbox, InoSnackbar, InoTooltip } from '@inovex.de/elements-react';
import { pushToServer } from 'apis/api';
import { reducer } from './AbsenceOverviewState';
import { useContext, createRef } from 'react';
import { ApiContext } from 'components/RequestManager/RequestManager';
import { useReorderAnimation } from 'shared/hooks/useReorderAnimation';

export interface AbsencesGroupedByMonth {
  [index: string]: AbsenceDashboardAbsenceItem[];
}

const LoadingSpinnerWrapper = styled.div`
  flex: 1 1 auto;
`;

const snackbarStyles = { '--ino-snackbar-right': '1rem', zIndex: '100', position: 'relative' };

const AbsencesOverview: React.FunctionComponent<
  React.PropsWithChildren<ApiProps<AbsencesGroupedByMonth> & RouteComponentProps>
> = ({ data, isLoading, error, history }) => {
  const user = useContext(ApiContext).getUser();
  const [absenceOverviewState, dispatch] = React.useReducer(reducer, {
    absencesByMonth: null,
    error: null,
    successMsg: false,
  });
  const listRef = React.useRef<HTMLTableSectionElement>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  useReorderAnimation(absenceOverviewState.absencesByMonth, listRef, scrollContainerRef);

  React.useEffect(() => {
    if (data) dispatch({ type: 'set-absence-data', payload: data });
  }, [data]);

  const noAbsencesData = () => (
    <tr>
      <td colSpan={payrollUser ? 8 : 7} style={{ textAlign: 'center' }}>
        Es wurden noch keine Abwesenheiten gefunden.{' '}
      </td>
    </tr>
  );

  const absenceIcons = {
    maternity_leave: process.env.PUBLIC_URL + '/icons/mom-mother-icon.svg',
    sabbatical_leave: process.env.PUBLIC_URL + '/icons/luggage_icon.svg',
    parental_leave: process.env.PUBLIC_URL + '/icons/family-parents-icon.svg',
  };

  const absenceLegend = {
    maternity_leave: 'Mutterschutz',
    sabbatical_leave: 'Auszeit',
    parental_leave: 'Elternzeit',
  };

  const getLayoutStateFromUrl = (): LayoutState =>
    window.location.pathname.includes('absences/detail') ? LayoutState.DETAIL : LayoutState.MASTER;

  const [activeAbsenceEvent, setActiveAbsenceEvent] = React.useState('');
  const [isDetailView, setIsDetailView] = React.useState(getLayoutStateFromUrl() === LayoutState.DETAIL ? true : false);

  const onDetailLoaded = (eventId: string) => {
    setActiveAbsenceEvent(eventId);
    setIsDetailView(true);
  };

  const onDetailClosed = () => {
    setActiveAbsenceEvent('');
    setIsDetailView(false);
    history.push(routes.absences.url);
  };

  const pushAbsenceChangeToServer = React.useCallback((event: CustomEvent, absence: AbsenceDashboardAbsenceItem) => {
    pushToServer(
      `${employeeUrl}/${absence.employee.id}`,
      {
        processedByPayroll: event.detail,
        eventId: absence.eventId,
      },
      {
        command: 'updateAbsenceProcessedByPayroll',
        method: 'PATCH',
      }
    ).then(
      () => {
        dispatch({
          type: 'set-success-message',
          payload: true,
        });

        dispatch({
          type: 'toggle-performed-by-payroll',
          payload: {
            eventId: absence.eventId,
            processedByPayroll: event.detail,
          },
        });
      },
      (err) => {
        dispatch({
          type: 'set-error',
          payload: err,
        });
      }
    );
  }, []);

  const debouncedPushToServer = React.useCallback(_.debounce(pushAbsenceChangeToServer, 500), []);
  const payrollUser = isPayrollUser(user);
  const variableColumns = payrollUser ? 4 : 3;
  const stickynessColumn2 = payrollUser ? '5rem' : '0rem';
  const stickynessColumn3 = payrollUser ? '10rem' : '5rem';
  const stickynessColumn4 = payrollUser ? '14rem' : '10rem';

  return (
    <>
      {isLoading && !absenceOverviewState?.absencesByMonth && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner />
        </LoadingSpinnerWrapper>
      )}
      {!error && (
        <MasterDetail size={isDetailView ? '70%' : '100%'} noShadow>
          <TableContainer ref={scrollContainerRef} stickyColumns={variableColumns} showDetailPage={isDetailView}>
            {!isLoading && (
              <MonthOverviewTable $defaultColumnWidth="328px">
                <thead>
                  <tr>
                    {payrollUser && (
                      <TableHeader width={'100px'} $stickyLeft="0rem">
                        <TableHeadSpace />
                      </TableHeader>
                    )}
                    <TableHeader width={'100px'} $stickyLeft={stickynessColumn2}>
                      <TableHeadSpace />
                    </TableHeader>
                    <TableHeader width={'80px'} $stickyLeft={stickynessColumn3}>
                      <TableHeaderElementWrapper interactive={false} label="Art">
                        <span></span>
                      </TableHeaderElementWrapper>
                    </TableHeader>
                    <TableHeader width={'220px'} $stickyLeft={stickynessColumn4}>
                      <TableHeaderElementWrapper interactive={false} label="Mitarbeiter:in">
                        <span></span>
                      </TableHeaderElementWrapper>
                    </TableHeader>
                    <TableHeader width={'120px'}>
                      <TableHeaderElementWrapper interactive={false} label="Start">
                        <span></span>
                      </TableHeaderElementWrapper>
                    </TableHeader>
                    <TableHeader>
                      <TableHeaderElementWrapper interactive={false} label="Ende">
                        <span></span>
                      </TableHeaderElementWrapper>
                    </TableHeader>
                    <TableHeader>
                      <TableHeaderElementWrapper interactive={false} label="Erstellt">
                        <span></span>
                      </TableHeaderElementWrapper>
                    </TableHeader>
                    <TableHeader>
                      <TableHeaderElementWrapper interactive={false} label="Aktion">
                        <span></span>
                      </TableHeaderElementWrapper>
                    </TableHeader>
                  </tr>
                </thead>
                <tbody ref={listRef}>
                  {absenceOverviewState.absencesByMonth &&
                  Object.entries(absenceOverviewState.absencesByMonth).length > 0
                    ? Object.entries(absenceOverviewState.absencesByMonth).map(([monthDate, absencesOfMonth], i) => (
                        <React.Fragment key={`${monthDate}-${i}`}>
                          <MonthOverviewHeaderRow data-key={null}>
                            <td colSpan={4}>{getMonthGroupHeaderText(monthDate, absencesOfMonth.length)}</td>
                            <td colSpan={variableColumns} />
                          </MonthOverviewHeaderRow>
                          {absencesOfMonth.map((absence, idx) => (
                            <tr
                              key={`${absence.eventId}`}
                              data-key={`${absence.eventId}`}
                              onClick={() => {
                                history.push(`${routes.absence_detail.createUrl({ id: absence.eventId })}`);
                                onDetailLoaded(absence.eventId);
                              }}
                              className={absence.eventId === activeAbsenceEvent ? 'active' : ''}
                            >
                              {payrollUser && (
                                <StickyProp $stickyLeft="0rem">
                                  <InoCheckbox
                                    data-cy="processedByPayroll"
                                    aria-label="Bereits erledigt"
                                    name="processedByPayroll"
                                    onClick={(event) => event.stopPropagation()}
                                    checked={absence.processedByPayroll}
                                    onCheckedChange={(event) => {
                                      debouncedPushToServer(event, absence);
                                    }}
                                  ></InoCheckbox>
                                </StickyProp>
                              )}
                              <StickyProp $stickyLeft={stickynessColumn2}>
                                <EmployeeShortInfo employeeId={absence.employee.id} loading="lazy" />
                              </StickyProp>
                              <StickyProp $stickyLeft={stickynessColumn3}>
                                <CustomInoIcon
                                  id={`absenceicon-${absence.eventId}`}
                                  size={'1.5rem'}
                                  src={absenceIcons[absence.absenceType as keyof typeof absenceIcons]}
                                  color={iconGreyMedium}
                                ></CustomInoIcon>
                                <InoTooltip
                                  for={`absenceicon-${absence.eventId}`}
                                  placement="right"
                                  trigger="mouseenter"
                                  colorScheme={'light'}
                                >
                                  {absenceLegend[absence.absenceType as keyof typeof absenceIcons]}
                                </InoTooltip>
                              </StickyProp>
                              <StickyProp
                                $stickyLeft={stickynessColumn4}
                              >{`${absence.employee.name.firstName}, ${absence.employee.name.lastName}`}</StickyProp>
                              <Prop>{formatDateToString(absence.start)}</Prop>
                              <Prop>{formatDateToString(absence.end)}</Prop>
                              <Prop>{formatDateToString(absence.createdAt)}</Prop>
                              <Prop>{mapActionToText(absence.action)}</Prop>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))
                    : noAbsencesData()}
                </tbody>
              </MonthOverviewTable>
            )}
          </TableContainer>
          <Switch>
            <Route
              path={routes.absence_detail.url}
              render={({ match }) => {
                const selectedEventId = activeAbsenceEvent || match.params.id;
                const selectedAbsence = Object.values(absenceOverviewState?.absencesByMonth ?? {})
                  ?.flat()
                  ?.filter((itm) => itm.eventId === selectedEventId)
                  ?.shift();
                if (selectedAbsence) {
                  return (
                    <AbsenceDetail absence={selectedAbsence} onDetailClosed={() => onDetailClosed()}></AbsenceDetail>
                  );
                }
                return <ErrorPages error={new Error('415')} />;
              }}
            />
          </Switch>
        </MasterDetail>
      )}
      {absenceOverviewState.error && (
        <InoSnackbar
          style={snackbarStyles}
          type="error"
          timeout={-1}
          onHideEl={() =>
            dispatch({
              type: 'set-error',
              payload: null,
            })
          }
        >
          {'Ein unerwarteter Fehler ist aufgetreten.'}
        </InoSnackbar>
      )}

      {absenceOverviewState.successMsg && (
        <InoSnackbar
          style={snackbarStyles}
          type="success"
          timeout={2000}
          onHideEl={() =>
            dispatch({
              type: 'set-success-message',
              payload: false,
            })
          }
        >
          {'Erfolgreich gespeichert.'}
        </InoSnackbar>
      )}
      {error && error.getResponse().status === 403 && !isLoading && <ErrorPages error={error} />}
    </>
  );
};

const ConnectedAbsencesOverview = connectToApi(AbsencesOverview, (api: SharedContext) =>
  api.fetch<AbsenceDashboardAbsenceItem[]>(absenceUrl).then((absences: AbsenceDashboardAbsenceItem[]) => {
    // Group Absences by Month
    const monthGroups = _.groupBy(_.orderBy(absences, 'eventStart', 'desc'), (a) => {
      const d = toMoment(a.eventStart);
      const month = d.format('MMMM');
      const year = d.year();
      return `${month} ${year}`;
    });

    return _.mapValues(monthGroups);
  })
);

export { ConnectedAbsencesOverview as AbsencesOverview };
