export interface Errors {
  [key: string]: string | Errors;
}

export const errors: Errors = {
  generic: 'Es ist ein Fehler aufgetreten',
  employee: {
    notFound: 'MitarbeiterIn konnte nicht gefunden werden',
    noEmploymentPeriods: 'Mitarbeiter hat keine Beschäftigungszeiträume',
    employmentPeriod: {
      notNull: 'Beschäftigungszeitraum existiert nicht',
      startNotNull: 'Eintrittsdatum fehlt',
      startNotAfterEnd: 'Das Enddatum liegt vor dem Eintrittsdatum',
      startNotPast: 'Eintrittsdatum liegt in der Vergangenheit',
      startNotContractStart: 'Datum ist kein Vertragsstart',
      noPreviousEmploymentPeriods: '',
      overlap: 'Die Beschäftigungszeiträume überschneiden sich',
      intern: {
        notNull: 'Beschäftigungszeitraum für Praktikant/in existiert nicht',
        notOpenEnd: 'Bachelor- oder Masterstudierende müssen befristet sein',
      },
      thesis: {
        notOpenEnd: 'Bachelor- oder Masterstudierende müssen befristet sein',
      },
      nothing: 'Es existieren keine Beschäftigungszeiträume',
      notUnique: 'Beschäftigungszeitraum konnte nicht eindeutig bestimmt werden',
      noIntersections: 'Es gibt keine Überschneidungen mit einem Beschäftigungszeitraum',
      createError: 'Beschäftigungszeitraum konnte nich angelegt werden',
      onlyShortened: 'Beschäftigungszeiträume können nur gekürzt werden',
    },
    planningGroup: {
      notUnique: 'Die Planungsgruppe fehlt oder kann nicht bestimmt werden',
    },
    update: {
      nothingChanged: 'Neuer und alter Wert sind gleich',
      activationDate: {
        notFuture: 'Aktivierungsdatum muss in der Zukunft liegen',
      },
      jobTitle: {
        onlyPermanentlyEmployed: 'Jobtitel kann nur für festangestellte Mitarbeitende angegeben werden',
        notNull: 'Jobtitel fehlt',
      },
      supervisor: {
        notNull: 'Betreuer fehlt',
        notExists: 'Der Betreuer existiert nicht',
        notActiveAtDate: 'Betreuer ist zum angegebenen Zeitpunkt nicht angestellt',
      },
      academicSubject: {
        notNull: 'Thesistitel oder Praktikumsthema fehlt',
        topic: {
          notNull: 'Thesistitel oder Praktikumsthema darf nicht leer sein',
          empty: 'Thesistitel oder Praktikumsthema darf nicht angegeben werden',
          onlyInternOrThesisStudent:
            'Thesistitel oder Praktikumsthema nur für Praktikant oder Bachelor- oder Masterstudierende',
        },
        internshipType: {
          mustBeNull: 'Praktikumsart darf nur für Praktikant/innen angegeben werden',
          notNull: 'Praktikumsart muss angegeben werden',
        },
      },
      workingHours: {
        notNull: 'Wochenarbeitszeiten fehlen',
        mustBeZero: 'Wochenarbeitsstunden müssen 0 sein',
        greaterZero: 'Wochenarbeitsstunden müssen größer 0 sein',
        fteHigherZero: 'FTE muss größer 0 sein',
      },
      holidayEntitlement: {
        notNull: 'Urlaubsanspruch fehlt',
        annualHolidaysNotNull: 'Jahresurlaub fehlt',
        remainingHolidaysNotNull: 'Resturlaub fehlt',
      },
      emergencyContact: {
        emailOrTelephoneNotNull: 'Emailadresse oder Telefonnummer muss angegeben werden',
      },
      location: {
        notNull: 'Standort fehlt',
        notExists: 'Standort existiert nicht',
      },
      ebr: {
        unique: 'EBR konnte nicht eindeutig ermittelt werden',
      },
      costCenter: {
        unique: 'Kostenstelle konnte nicht eindeutig ermittelt werden',
      },
      employmentStatus: {
        notIntern: 'Anstellungsstatus ist nicht Praktikant',
        notThesisStudent: 'Anstellungsstatus ist nicht Bachelor- oder Masterstudent',
        notThesisStudentOrIntern: 'Anstellungsstatus muss Praktikant oder Bachelor- oder Masterstudent sein',
        notPermanentlyEmployed: 'Anstellungsstatus ist nicht Festangestellt',
        notWorkingStudent: 'Anstellungsstatus ist nicht Werkstudent',
        notOperative: 'Anstellungsstatus ist nicht Festangestellt Operativ',
        notLeading: 'Anstellungsstatus ist nicht Festangestellt Leitend',
        notTrainee: 'Anstellungsstatus ist nicht Student, Auszubildend oder Praktikant',
        notTemporaryHelp: 'Anstellungsstatus ist nicht Aushilfe',
        notStudent: 'Anstellungsstatus ist nicht Student',
        notUnique: 'Anstellungsstatus ist nicht eindeutig',
      },
      salary: {
        notNull: 'Gehalt fehlt',
        positive: 'Gehalt muss positiv sein',
        period: {
          monthRequired: 'Gehalt muss monatlich angegeben werden',
          hourRequired: 'Gehalt muss mstündlich angegeben werden',
        },
      },
      noTeam: 'Team fehlt',
      noTeamLead: 'Team Lead fehlt',
      team: {
        invalid: 'Ungültiges Team',
      },
      teamLead: {
        notActiveAtDate: 'Mitarbeiter ist kein Team Lead zum angegebenen Zeitpunkt',
        mustHaveNoEmployees: 'Dem Team Lead sind noch Mitarbeiter zugeordnet',
      },
      mentor: {
        notActiveAtDate: 'Mentor ist zum angegebenen Zeitpunkt nicht angestellt',
        notExists: 'Mentor existiert nicht',
      },
      probationEnd: {
        notBeforeStart: 'Probezeitende liegt vor dem Startdatum',
        notAfterEnd: 'Probezeitende liegt nach dem Enddatum',
        notPast: 'Probezeitende liegt in der Vergangenheit',
      },
      performanceReviewMonth: {
        onlyPermanentlyEmployed: 'JAG Datum kann nur für Festangestellte und Azubis angegeben werden',
        invalid: 'JAG Datum ist kein gültiger Monat',
      },
      personalData: {
        mail: {
          notNull: 'Emailadresse fehlt',
        },
      },
    },
    validation: {
      fte: {
        violated: 'Ungültiger FTE',
      },
      startDate: {
        notNull: 'Eintrittsdatum fehlt',
      },
      endDate: {
        notNull: 'Vertragsende fehlt',
      },
      startAfterEnd: 'Eintrittsdatum liegt hinter Vertragsende',
      endAfterProbation: 'Vertragsende liegt hinter Probezeitende',
      name: {
        notNull: 'Name fehlt',
      },
      firstName: {
        notEmpty: 'Vorname fehlt',
      },
      lastName: {
        notEmpty: 'Nachname fehlt',
      },
      salary: {
        notNull: 'Gehalt fehlt',
        positive: 'Gehalt muss positiv sein',
        value: {
          positive: 'Gehalt muss größer 0€ sein',
          notNull: 'Gehalt fehlt',
        },
        period: {
          monthRequired: 'Gehalt muss monatlich angegeben werden',
          hourRequired: 'Gehalt muss stündlich angegeben werden',
          hourLimit: 'Gehalt muss stündlich unter 100€ liegen',
          notEmpty: 'Gehalt monatlich oder stündlich fehlt',
        },
      },
      academicSubject: {
        notNull: 'Thesistitel oder Praktikumsthema fehlt',
        mustBeNull: 'Es darf kein Thesistitel oder Praktikumsthema angegeben werden',
        topic: {
          notNull: 'Thesistitel oder Praktikumsthema darf nicht leer sein',
          empty: 'Thesistitel oder Praktikumsthema darf nicht angegeben werden',
          onlyInternOrThesisStudent:
            'Thesistitel oder Praktikumsthema nur für Praktikant oder Bachelor- oder Masterstudierende',
        },
        internshipType: {
          mustBeNull: 'Praktikumsart darf nur für Praktikant/innen angegeben werden',
          notNull: 'Praktikumsart muss angegeben werden',
        },
        supervisor: {
          notNull: 'Betreuer fehlt',
        },
      },
      birthday: {
        notNull: 'Geburtstag fehlt',
        past: 'Geburtstag muss in der Vergangenheit liegen',
      },
      salutation: {
        pattern: 'Anrede muss Divers, Frau oder Herr sein',
      },
      degree: {
        notEmpty: 'Höchster Abschluss fehlt',
      },
      mail: {
        invalid: 'Emailadresse ungültig',
        notEmpty: 'Emailadresse fehlt',
      },
      telephone: {
        invalid: 'Telefonnummer ungültig',
        notEmpty: 'Telefonnummer fehlt',
      },
      privateContact: {
        notNull: 'Private Kontaktdaten fehlen',
        street: {
          notEmpty: 'Straße fehlt',
        },
        zipcode: {
          notEmpty: 'Postleitzahl fehlt',
        },
        city: {
          notEmpty: 'Stadt fehlt',
        },
      },
      jobTitle: {
        notEmpty: 'Jobtitel fehlt',
        mustBeEmpty: 'Jobtitel darf nicht angegeben werden',
      },
      probationEnd: {
        notNull: 'Probezeitende fehlt',
      },
      team: {
        notNull: 'Team fehlt',
      },
      teamLead: {
        notNull: 'Team Lead fehlt',
      },
      mentor: {
        notNull: 'Mentor fehlt',
      },
      costCenter: {
        notNull: 'Kostenstelle ungültig',
      },
      ebr: {
        notNull: 'EBR ungültig',
      },
      holidayEntitlement: {
        notNull: 'Urlaubsanspruch fehlt',
        mustBeZero: 'Urlaubsanspruch muss 0 sein',
        annual: {
          positive: 'Jahresurlaub darf nicht negativ sein',
        },
        remaining: {
          positive: 'Resturlaub darf nicht negativ sein',
        },
      },
      workingHours: {
        notNull: 'Wochenarbeitszeiten fehlen',
        max: 'Arbeitsstunden pro Tag darf nicht größer 10 sein',
        positive: 'Arbeitsstunden müssen größer gleich 0 sein',
        mustBeZero: 'Arbeitsstunden müssen 0 sein',
      },
      employmentStatus: {
        notNull: 'Anstellungsstatus fehlt',
        invalidRequest: 'Ungültiger Anstellungsstatuswechsel.',
      },
      location: {
        notNull: 'Standort fehlt',
        invalid: 'Ungültiger Standort',
      },
      employmentPeriod: {
        costCenter: {
          notExpected: 'Unerwartete Kostenstelle',
          invalid: 'Ungültige Kostenstelle',
        },
        planningGroup: {
          notExpected: 'Unerwartete Planungsgruppe',
          invalid: 'Ungültige Planungsgruppe',
        },
        team: {
          notExpected: 'Unerwartetes Team',
          invalid: 'Ungültiges Team',
        },
        ebr: {
          notExpected: 'Unerwarteter EBR',
          invalid: 'Ungültiger EBR',
        },
        employmentStatus: {
          notExpected: 'Unerwarteter Anstellungsstatus',
          invalid: 'Ungültiger Anstellungsstatus',
        },
      },
      recruitmentInfo: {
        notNull: 'Recruiting Infos fehlen',
        source: {
          notNull: 'Recruiting Quelle fehlt',
        },
        sourceDetail: {
          notEmpty: 'Recruiting Quelle fehlt',
        },
        level: {
          notNull: 'Recruiting Level fehlt',
          notTrainee: 'Recruiting Level darf nicht Trainee oder leer sein',
          mustBeTrainee: 'Recruiting Level muss Trainee sein',
        },
      },
      avatar: {
        invalid: 'Ungültiges Bildformat',
      },
      note: {
        notNull: 'Notiz fehlt',
      },
      expectedEndOfStudy: {
        onlyStudents: 'Erwatetes Studienende darf nicht angegeben werden',
      },
    },
  },
  onboarding: {
    illegalState: 'Onboarding befindet sich in keinem gültigen Zustand',
    alreadyCompletedOrCanceled: 'Das Onboarding ist bereits abgeschlossen oder abgebrochen',
    alreadyStarted: 'Das Onboarding wurde bereits gestartet',
    pending: 'Onboarding wurde noch nicht gestartet',
    alreadyInvalidated: 'Onboarding ist bereits ungültig',
    update: {
      nothingChanged: 'Neuer und alter Wert sind gleich',
    },
    validation: {
      costCenter: {
        notExpected: 'Unerwartete Kostenstelle',
      },
      planningGroup: {
        notExpected: 'Unerwartete Planungsgruppe',
      },
      team: {
        notExpected: 'Unerwartetes Team',
      },
      ebr: {
        notExpected: 'Unerwarteter EBR',
      },
      employmentStatus: {
        notExpected: 'Unerwarteter Anstellungsstatus',
      },
      emergencyContact: {
        invalid: 'Notfallkontakt Email oder Telefonnummer fehlt',
      },
      referredBy: {
        notEmpty: 'Werbender Mitarbeiter fehlt',
        notAllowed: 'Werbeneder Mitarbeiter darf nicht angegeben werden',
      },
    },
  },
  offboarding: {
    submittedDate: {
      required: 'Datum wann die Kündigung eingereicht wurde fehlt',
    },
    alreadyStarted: 'Offboarding wurde bereits gestartet',
    offboardingDate: {
      notNull: 'Austrittdatum fehlt',
      notBeforeStart: 'Austrittsdatum liegt vor dem Startdatum',
      noEmploymentPeriod: 'Es existiert kein passender Beschäftigungszeitraum',
    },
    employmentPeriod: {
      notUnlimited: 'Beschäftigungszeitraum muss befristet sein',
    },
    update: {
      nothingChanged: 'Neue und alte Werte sind gleich',
      immutable: {
        employeeId: 'Mitarbeiter darf sich nicht ändern',
        note: 'Notiz aus Anlage kann nicht bearbeitet werden',
      },
    },
  },
};
