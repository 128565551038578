import styled from 'styled-components';
import { boxShadow, grey, greyLight, primary3 } from 'shared/constants/colors';

interface StyledTableContainerProps {
  $scrolledLeft: boolean;
  $showDetailPage: boolean;
  $stickyColumns: number;
}

export const StyledTableContainer = styled.div<StyledTableContainerProps>`
  position: relative;
  height: 100%;
  overflow-x: ${(props: StyledTableContainerProps) => (props.$showDetailPage ? 'none' : 'scroll')};
  overflow-y: scroll;
  scroll-behavior: smooth;
  box-shadow: ${(props: StyledTableContainerProps) =>
    props.$showDetailPage ? `0 0 20px 0 rgba(0, 0, 0, 0.3)` : 'none'};

  > table {
    width: 100%;
  }

  table tbody td {
    &:nth-child(3) {
      -moz-box-shadow: ${(props: StyledTableContainerProps) =>
        props.$scrolledLeft ? `5px 0 3px -3px ${boxShadow}` : 'none'};
      -webkit-box-shadow: ${(props: StyledTableContainerProps) =>
        props.$scrolledLeft ? `5px 0 3px -3px ${boxShadow}` : 'none'};
      box-shadow: ${(props: StyledTableContainerProps) =>
        props.$scrolledLeft ? `5px 0 3px -3px ${boxShadow}` : 'none'};
    }
    &:nth-child(-n + ${(props: StyledTableContainerProps) => props.$stickyColumns}) {
      background-color: ${(props: StyledTableContainerProps) => (props.$scrolledLeft ? 'white' : 'transparent')};
    }
  }
`;

export const Table = styled.table`
  border-spacing: 0;
  border: none;
  border-collapse: collapse;
  white-space: nowrap;

  tbody {
    scroll-behavior: smooth;
  }

  tr {
    &.active {
      background-color: ${greyLight};
      span {
        color: ${primary3};
      }
    }

    &:nth-child(n + 2):hover {
      border-bottom: solid ${grey} 2px;
    }
  }

  th,
  td {
    font-weight: 400;
    text-align: left;
    transition: max-width 0.5s ease-in-out;
    &:nth-child(n + 3) {
      min-width: 120px;
    }
  }

  th {
    position: sticky;
    top: 0;
    padding: 0;
    z-index: 10;
    font-size: 0.8125rem;
  }

  td {
    padding: 1rem 0.5rem;

    &:first-child {
      padding: 0;
    }
  }
`;
