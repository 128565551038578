import { FetchError } from 'apis/FetchError';
import * as React from 'react';
import { ErrorImg, ErrorMessage, ErrorPageWrapper } from 'components/ErrorPages/ErrorPages.styled';
import Image300 from './new_assets/ErrorPage300.svg';
import Image401 from './new_assets/ErrorPage401.svg';
import Image403 from './new_assets/ErrorPage403.svg';
import Image404 from './new_assets/ErrorPage404.svg';
import Image500 from './new_assets/ErrorPage500.svg';
import ImageGeneric from './new_assets/ErrorPageGeneric.svg';
import { FallbackProps } from 'react-error-boundary';

const ErrorPages: React.FunctionComponent<React.PropsWithChildren<Partial<FallbackProps>>> = ({ error }) => {
  const fetchError = error as FetchError;
  let errorStatus: number = fetchError?.getResponse && fetchError.getResponse()?.status;
  let errorMessage = '';
  let showHint = false;
  /*
    Errors thrown by us might not include responses
    therefore we provide the preferred http status code via message string.
   */
  if (!errorStatus) {
    const errorCodes = fetchError?.message.match(/\d+/);
    errorStatus = errorCodes ? parseInt(errorCodes[0], 10) : 0;
  }

  const getImage = (): string => {
    switch (errorStatus) {
      case 300:
        errorMessage = 'Leider konnte diese Seite nicht gefunden werden.';
        return Image300;
      case 401:
        errorMessage = 'Leider bist du nicht autorisiert JAMES zu nutzen.';
        showHint = true;
        return Image401;
      case 403:
        errorMessage = 'Leider hast du keine Berechtigung diese Seite von JAMES zu sehen.';
        showHint = true;
        return Image403;
      case 400:
      case 404:
        errorMessage = 'Leider konnte diese Seite nicht gefunden werden.';
        return Image404;
      case 500:
        errorMessage = 'Leider ist ein Fehler aufgetreten, bitte versuche es später erneut.';
        return Image500;
      default:
        errorMessage = 'Leider ist ein Fehler aufgetreten.';
        return ImageGeneric;
    }
  };
  return (
    <ErrorPageWrapper>
      <ErrorImg src={getImage()} alt={`Ein Fehler mit dem Status ${errorStatus} ist aufgetreten.`} />
      <ErrorMessage style={{ fontSize: '1.15rem' }}>{errorMessage}</ErrorMessage>
      {showHint && <ErrorMessage>Bitte wende dich gegebenenfalls an das INMA Team.</ErrorMessage>}
    </ErrorPageWrapper>
  );
};

export { ErrorPages };
