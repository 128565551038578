import { copyToClipboard } from 'components/AbsencesOverview/utils';
import * as React from 'react';
import { formatDateToString } from 'shared/utils/utils';
import styled from 'styled-components';

const AbsenceDetailHeaderWrapper = styled.div`
  flex: 1 1 auto;
  margin-bottom: 15px;
`;

const AbsenceDetailDate = styled.b`
  cursor: pointer;
`;

interface AbsenceDetailHeaderProps {
  start: Date;
  end: Date;
}

export const AbsenceDetailHeader: React.FunctionComponent<React.PropsWithChildren<AbsenceDetailHeaderProps>> = (
  props
) => (
  <AbsenceDetailHeaderWrapper>
    Vom{' '}
    <AbsenceDetailDate
      onClick={() => {
        copyToClipboard(formatDateToString(props.start));
      }}
    >
      {formatDateToString(props.start)}
    </AbsenceDetailDate>{' '}
    bis zum{' '}
    <AbsenceDetailDate
      onClick={() => {
        copyToClipboard(formatDateToString(props.end));
      }}
    >
      {formatDateToString(props.end)}
    </AbsenceDetailDate>
  </AbsenceDetailHeaderWrapper>
);
