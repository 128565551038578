import * as React from 'react';
import { StyledTableContainer } from './Table.styled';
import { useImperativeHandle, useCallback, useEffect } from 'react';

interface TableContainerProps {
  showDetailPage: boolean;
  stickyColumns?: number;
}

export const TableContainer = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<JSX.IntrinsicAttributes & TableContainerProps & React.RefAttributes<HTMLDivElement>>
>(({ showDetailPage, stickyColumns = 3, children }, ref) => {
  const innerRef = React.useRef<HTMLDivElement>(null);
  const [scrolledLeft, setScrolledLeft] = React.useState(false);

  useEffect(() => {
    innerRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      innerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [innerRef]);

  useImperativeHandle<HTMLDivElement, HTMLDivElement>(ref, () => innerRef.current!, []);

  const handleScroll = useCallback(() => {
    if (innerRef.current) {
      if (innerRef.current.scrollLeft > 0) {
        setScrolledLeft(true);
      } else if (innerRef.current.scrollLeft === 0) {
        setScrolledLeft(false);
      }
    }
  }, [innerRef]);

  return (
    <StyledTableContainer
      id="table-container"
      ref={innerRef}
      $scrolledLeft={scrolledLeft}
      $showDetailPage={showDetailPage}
      $stickyColumns={stickyColumns}
    >
      {children}
    </StyledTableContainer>
  );
});
