import * as React from 'react';
import { employeeUrl, salaryUrl } from 'apis/api/url-constants';
import { connectToApi } from 'components/RequestManager/connectToApi';
import { ApiProps, SharedContext } from 'shared/types/interfaces/Api';
import { EmployeeSalaryAdjustment } from 'shared/types/interfaces/Employee';
import {
  MonthOverviewHeaderRow,
  MonthOverviewTable,
  TableHeader,
} from 'shared/components/Overview/OverviewTable.styled';
import { TableHeaderElementWrapper } from 'shared/components/TableHeaderElementWrapper';
import { TableHeadSpace } from 'shared/components/TableHeader/TableHeader.styled';

import { toMoment } from 'shared/utils/format';

import { getMonthGroupHeaderText } from './utils';
import ErrorPages from 'components/ErrorPages';
import { LoadingSpinner } from 'components/LoadingSpinner';
import styled from 'styled-components';
import { InoSnackbar } from '@inovex.de/elements-react';
import { useEffect, useReducer } from 'react';
import { SalaryTableRow } from './SalaryTableRow/SalaryTableRow';
import _ from 'lodash';
import { reducer } from './SalaryOverviewState';
import { pushToServer } from 'apis/api';
import { useReorderAnimation } from 'shared/hooks/useReorderAnimation';
import { TableContainer } from 'components/EmployeeOverview/Table';

const LoadingSpinnerWrapper = styled.div`
  flex: 1 1 auto;
`;

const snackbarStyles = { '--ino-snackbar-right': '1rem', zIndex: '100', position: 'relative' };

export interface GroupedEmployeeSalaryAdjustments {
  [index: string]: EmployeeSalaryAdjustment[];
}

const SalaryOverview: React.FunctionComponent<ApiProps<GroupedEmployeeSalaryAdjustments>> = ({
  data,
  isLoading,
  error,
  refreshData,
}) => {
  const [salaryOverviewState, dispatch] = useReducer(reducer, {
    salariesByMonth: null,
    error: null,
    successMsg: false,
  });
  const listRef = React.useRef<HTMLTableSectionElement>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  useReorderAnimation(salaryOverviewState.salariesByMonth, listRef, scrollContainerRef);

  useEffect(() => {
    if (data) dispatch({ type: 'set-salary-data', payload: data });
  }, [data]);

  useEffect(() => {
    dispatch({ type: 'set-error', payload: error });
  }, [error]);

  const noSalaryData = () => (
    <tr>
      <td colSpan={9} style={{ textAlign: 'center' }}>
        Es wurden noch keine Gehaltsanpassungen vorgenommen.{' '}
      </td>
    </tr>
  );

  const pushSalaryChangeToServer = React.useCallback(
    (event: CustomEvent, salary: EmployeeSalaryAdjustment) => {
      const processedByPayroll = {
        processedByPayroll: event.detail,
        from: salary.from,
        salary: {
          ...salary.salary,
          value: (salary.salary.value as number).toFixed(2),
        },
        context: salary.context,
      };

      pushToServer(`${employeeUrl}/${salary.employee.id}`, processedByPayroll, {
        command: 'updateSalaryAdjustmentProcessedByPayroll',
        method: 'PATCH',
      }).then(
        () => {
          dispatch({
            type: 'set-success-message',
            payload: true,
          });

          dispatch({
            type: 'toggle-performed-by-payroll',
            payload: {
              from: salary.from as string,
              salary: salary.salary.value as number,
              context: salary.context,
              processedByPayroll: event.detail,
              employeeId: salary.employee.id,
            },
          });
        },
        (err) => {
          dispatch({
            type: 'set-error',
            payload: err,
          });
          refreshData();
        }
      );
    },
    [data]
  );

  const debouncedPushToServer = React.useCallback(_.debounce(pushSalaryChangeToServer, 500), []);

  return (
    <>
      {isLoading && !salaryOverviewState.salariesByMonth && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner />
        </LoadingSpinnerWrapper>
      )}
      {!error && (
        <TableContainer ref={scrollContainerRef} showDetailPage={false}>
          {!isLoading && (
            <MonthOverviewTable>
              <thead>
                <tr>
                  <TableHeader width="64px" $stickyLeft="0rem">
                    <TableHeadSpace />
                  </TableHeader>
                  <TableHeader width="80px" $stickyLeft="4rem">
                    <TableHeadSpace />
                  </TableHeader>
                  <TableHeader $stickyLeft="9rem">
                    <TableHeaderElementWrapper interactive={false} label="Name">
                      <span></span>
                    </TableHeaderElementWrapper>
                  </TableHeader>
                  <TableHeader>
                    <TableHeaderElementWrapper interactive={false} label="Anstellungsstatus">
                      <span></span>
                    </TableHeaderElementWrapper>
                  </TableHeader>
                  <TableHeader>
                    <TableHeaderElementWrapper interactive={false} label="Gültig ab">
                      <span></span>
                    </TableHeaderElementWrapper>
                  </TableHeader>
                  <TableHeader>
                    <TableHeaderElementWrapper interactive={false} label="Neues Gehalt (VZ)">
                      <span></span>
                    </TableHeaderElementWrapper>
                  </TableHeader>
                  <TableHeader>
                    <TableHeaderElementWrapper interactive={false} label="Neues Gehalt (TZ)">
                      <span></span>
                    </TableHeaderElementWrapper>
                  </TableHeader>
                  <TableHeader>
                    <TableHeaderElementWrapper interactive={false} label="Wochenstunden">
                      <span></span>
                    </TableHeaderElementWrapper>
                  </TableHeader>
                  <TableHeader>
                    <TableHeaderElementWrapper interactive={false} label="Kontext">
                      <span></span>
                    </TableHeaderElementWrapper>
                  </TableHeader>
                  <TableHeader>
                    <TableHeaderElementWrapper interactive={false} label="Erstellt">
                      <span></span>
                    </TableHeaderElementWrapper>
                  </TableHeader>
                  <TableHeader>
                    <TableHeadSpace />
                  </TableHeader>
                </tr>
              </thead>

              <tbody ref={listRef}>
                {salaryOverviewState.salariesByMonth && Object.entries(salaryOverviewState.salariesByMonth).length > 0
                  ? Object.entries(salaryOverviewState.salariesByMonth).map(([monthDate, salariesOfMonth], i) => {
                      if (salariesOfMonth.length === 0) return;
                      return (
                        <React.Fragment key={i}>
                          <MonthOverviewHeaderRow>
                            <td colSpan={3}>{getMonthGroupHeaderText(monthDate, salariesOfMonth.length)}</td>
                            <td colSpan={9} />
                          </MonthOverviewHeaderRow>
                          {salariesOfMonth.map((salary, index) => (
                            <SalaryTableRow
                              salary={salary}
                              key={index}
                              toggleProcessedByPayroll={(event, salary) => {
                                debouncedPushToServer(event, salary);
                              }}
                            ></SalaryTableRow>
                          ))}
                        </React.Fragment>
                      );
                    })
                  : noSalaryData()}
              </tbody>
            </MonthOverviewTable>
          )}
        </TableContainer>
      )}
      {salaryOverviewState.error && (
        <InoSnackbar
          style={snackbarStyles}
          type="error"
          timeout={-1}
          onHideEl={() =>
            dispatch({
              type: 'set-error',
              payload: null,
            })
          }
        >
          {'Ein unerwarteter Fehler ist aufgetreten.'}
        </InoSnackbar>
      )}

      {salaryOverviewState.successMsg && (
        <InoSnackbar
          style={snackbarStyles}
          type="success"
          timeout={2000}
          onHideEl={() =>
            dispatch({
              type: 'set-success-message',
              payload: false,
            })
          }
        >
          {'Erfolgreich gespeichert.'}
        </InoSnackbar>
      )}

      {error && error.getResponse().status === 403 && !isLoading && <ErrorPages error={error} />}
    </>
  );
};

const ConnectedSalaryOverview = connectToApi(SalaryOverview, (api: SharedContext) =>
  api.fetch<EmployeeSalaryAdjustment[]>(salaryUrl).then((salaries: EmployeeSalaryAdjustment[]) => {
    const monthGroups = _.groupBy(
      _.orderBy(salaries, ['from', 'createdAt', 'employee.name.lastName'], ['desc', 'asc', 'asc']),
      (a) => {
        const d = toMoment(a.from);
        const month = d.format('MMMM');
        const year = d.year();
        return `${month} ${year}`;
      }
    );

    const salariesByMonth = _.mapValues(monthGroups);

    return Object.entries(salariesByMonth).reduce(
      (prev, [month, salaries]) => ({
        ...prev,
        [month]: salaries.filter((s) => s.salary).reverse(),
      }),
      {} as GroupedEmployeeSalaryAdjustments
    );
  })
);

export { ConnectedSalaryOverview as SalaryOverview };
