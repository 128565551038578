import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CustomInoIcon } from 'shared/style/CustomInoIcon';

interface CloseViewIconButtonProps {
  to: string;
  onClick?: () => void;
}

export const CloseViewIconButton: React.FunctionComponent<React.PropsWithChildren<CloseViewIconButtonProps>> = (props) => (
  <WrappedLink to={props.to}>
    <CustomInoIcon icon="close" size="1.1rem" hoverEffect />
  </WrappedLink>
);

export const WrappedLink = styled(Link)`
  position: absolute;
  top: 1.5rem;
  left: 2rem;
`;
