import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { matchPath } from 'react-router';

import { InoIcon, InoList, InoNavItem } from '@inovex.de/elements-react';
import { routes } from 'components/Application/routes';
import { Avatar, AvatarSize } from 'components/Avatar/Avatar';
import { InovexWrapper, JamesLogo, StyledNavLink } from './NavDrawerContent.styled';
import { getUserRole, isAdminUser, isFinanceUser, isPayrollUser, isPncUser, isTeamLeadUser } from 'shared/utils/utils';
import { ApiContext } from 'components/RequestManager/RequestManager';
import { useLocation } from 'react-router-dom';

const NavDrawerContent: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const user = useContext(ApiContext).getUser();

  const userRole = user ? getUserRole(user) : '';

  const location = useLocation();
  const isActive = (url: string, exact = false) => {
    if (user && location.pathname.includes(user.uuid)) {
      // don't highlight employee link when on own profile
      if (url === '/employee') {
        return false;
      }
    }
    return !!matchPath(location.pathname, { path: url, exact });
  };

  const [teamLead, isTeamLead] = useState(false);

  useEffect(() => {
    if (user) {
      isTeamLeadUser(user)
        .then((v) => isTeamLead(v))
        .catch((err) => {
          isTeamLead(false);
          console.log(err);
        });
    } else {
      isTeamLead(false);
    }
  }, [user]);

  const showReportNavLink = isAdminUser(user) || isPncUser(user) || isFinanceUser(user) || !!teamLead;
  const showOffboardingNavLink = isAdminUser(user) || isPncUser(user) || !!teamLead;

  return (
    <>
      <div slot="header">
        <StyledNavLink to={'/'}>
          <JamesLogo type="james" />
          <InovexWrapper>inovex</InovexWrapper>
        </StyledNavLink>
      </div>
      {user && (
        <>
          <InoList slot="content">
            <StyledNavLink to={routes.employee_overview.url}>
              <InoNavItem text="Mitarbeiter" activated={isActive(routes.employee_overview.url)}>
                <InoIcon icon="employee" />
              </InoNavItem>
            </StyledNavLink>
            {isPncUser(user) && (
              <StyledNavLink to={routes.onboarding.url}>
                <InoNavItem text="Onboarding" activated={isActive(routes.onboarding.url)}>
                  <InoIcon icon="onboarding" />
                </InoNavItem>
              </StyledNavLink>
            )}
            <StyledNavLink to={routes.offboarding.url} style={{ display: showOffboardingNavLink ? 'block' : 'none' }}>
              <InoNavItem text="Offboarding" activated={isActive(routes.offboarding.url)}>
                <InoIcon icon="offboarding" />
              </InoNavItem>
            </StyledNavLink>
            {isPayrollUser(user) && (
              <StyledNavLink to={routes.salary.url}>
                <InoNavItem text="Gehälter" activated={isActive(routes.salary.url)}>
                  <InoIcon src={process.env.PUBLIC_URL + '/icons/euro-icon.svg'} />
                </InoNavItem>
              </StyledNavLink>
            )}
            {(isAdminUser(user) || isPayrollUser(user)) && (
              <StyledNavLink to={routes.absences.url}>
                <InoNavItem text="Abwesenheiten" activated={isActive(routes.absences.url)}>
                  <InoIcon src={process.env.PUBLIC_URL + '/icons/absences_nav_icon.svg'} />
                </InoNavItem>
              </StyledNavLink>
            )}
            <StyledNavLink to={routes.reports.url} style={{ display: showReportNavLink ? 'block' : 'none' }}>
              <InoNavItem text="Reports" activated={isActive(routes.reports.url)}>
                <InoIcon icon="report" />
              </InoNavItem>
            </StyledNavLink>
          </InoList>
          <InoList slot="footer">
            <StyledNavLink to={routes.about.url}>
              <InoNavItem text="Über James" activated={isActive(routes.about.url)}>
                <InoIcon icon="info" />
              </InoNavItem>
            </StyledNavLink>
            <InoNavItem
              disabled
              className="mdc-list--two-line" // Fix https://github.com/inovex/elements/issues/284
              text={`${user.firstName} ${user.surname}`}
              subText={`${userRole}`}
            >
              <Avatar uuid={user.uuid} size={AvatarSize.SMALL} />
            </InoNavItem>
          </InoList>
        </>
      )}
    </>
  );
};

export { NavDrawerContent };
