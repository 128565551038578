import { FetchError } from 'apis/FetchError';
import _ from 'lodash';
import { AbsencesGroupedByMonth } from './AbsencesOverview';

export type Action =
  | {
      type: 'toggle-performed-by-payroll';
      payload: {
        eventId: string;
        processedByPayroll: boolean;
      };
    }
  | {
      type: 'set-error';
      payload: FetchError | null;
    }
  | {
      type: 'set-success-message';
      payload: boolean;
    }
  | {
      type: 'set-absence-data';
      payload: AbsencesGroupedByMonth;
    };

export interface AbsenceOverviewState {
  absencesByMonth: AbsencesGroupedByMonth | null;
  error: FetchError | null;
  successMsg: boolean;
}

export const reducer = (state: AbsenceOverviewState, action: Action) => {
  switch (action.type) {
    case 'set-error':
      return {
        ...state,
        error: action.payload,
      };
    case 'set-success-message':
      return {
        ...state,
        successMsg: action.payload,
      };
    case 'set-absence-data':
      return {
        ...state,
        absencesByMonth: sortMonthGroups(action.payload),
      };
    case 'toggle-performed-by-payroll':
      const absencesByMonth = Object.entries(state.absencesByMonth as AbsencesGroupedByMonth).reduce(
        (prev, [month, absences]) => ({
          ...prev,
          [month]: absences.map((absence) => ({
            ...absence,
            processedByPayroll:
              absence.eventId === action.payload.eventId
                ? action.payload.processedByPayroll
                : absence.processedByPayroll,
          })),
        }),
        {} as AbsencesGroupedByMonth
      );

      return {
        ...state,
        absencesByMonth: sortMonthGroups(absencesByMonth),
      };
  }
};

const sortMonthGroups = (monthGroups: AbsencesGroupedByMonth) => {
  const monthGroupsCpy = { ...monthGroups };

  Object.keys(monthGroupsCpy).forEach((month: string) => {
    monthGroupsCpy[month] = _.orderBy(monthGroupsCpy[month], ['processedByPayroll', 'createdAt'], ['asc', 'desc']);
  });

  return monthGroupsCpy;
};
