import * as React from 'react';
import styled from 'styled-components';
import { textDark, textLight, greyLight } from 'shared/constants/colors';

interface TitleProps {
  title: string;
  subtitle?: string | string[] | number | null;
  placeholder?: string;
  className?: string;
}

const Title1: React.FunctionComponent<React.PropsWithChildren<TitleProps>> = (props) => {
  const { className, title, placeholder, subtitle } = props;
  return (
    <Wrapper className={className}>
      <Title>{title}</Title>
      {Array.isArray(subtitle) ? (
        subtitle.map((entry, index) =>
          entry ? <Subtitle key={index}>{entry}</Subtitle> : <Placeholder key={index}>{placeholder}</Placeholder>
        )
      ) : subtitle ? (
        <Subtitle>{subtitle}</Subtitle>
      ) : props.children ? (
        <Content>{props.children}</Content>
      ) : (
        <Placeholder>{placeholder}</Placeholder>
      )}
    </Wrapper>
  );
};

const Title2 = styled(Title1)`
  > h3 {
    font-size: 0.75rem;
    color: ${textLight};
    line-height: 1.5625rem;
  }
  > p {
    font-size: 0.9375rem;
    color: ${textDark};
    line-height: 1.5625rem;
  }
`;

const Title3 = styled(Title1)`
  > h3 {
    font-size: 0.625rem;
  }
  > p {
    font-size: 0.9375rem;
  }
`;

const Title3Border = styled(Title3)`
  border-bottom: 1px solid ${greyLight};
`;

const Wrapper = styled.div`
  margin: 0.25rem 0;
  width: 100%;
  min-width: 6.25rem;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: ${textDark};
`;

const Subtitle = styled.p`
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: ${textLight};
`;

export const Placeholder = styled(Subtitle)`
  font-style: italic;
`;

const Content = styled(Subtitle)``;

export { Title1, Title2, Title3, Title3Border };
