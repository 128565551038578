import moment from 'moment';
import 'moment/locale/de';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { routes } from 'components/Application/routes';
import { RequestManager } from 'components/RequestManager';
import { unregister } from 'registerServiceWorker';
import { config } from 'config';
import { NavDrawer } from 'components/Application/NavDrawer';
import App from 'components/Application';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import ErrorPages from 'components/ErrorPages';

ReactDOM.render(
  <Router>
      <Switch>
        <Route exact path={routes.unauthorized.url} render={routes.unauthorized.component} />
        <ErrorBoundary FallbackComponent={ErrorPages as React.ComponentType<React.PropsWithChildren<FallbackProps>>}>
          <RequestManager>
            <NavDrawer>
              <Route component={App} />
            </NavDrawer>
          </RequestManager>
        </ErrorBoundary>
      </Switch>
  </Router>,
  document.getElementById('root')
);
unregister();
moment.locale(config.lang);
