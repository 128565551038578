import { AbsenceAction } from 'shared/types/interfaces/Absences';

export const getMonthGroupHeaderText = (monthDate: string, count = 0) => `${monthDate} (${count.toString()})`;

export const copyToClipboard = (text: string) => navigator.clipboard.writeText(text);

export const mapActionToText = (action: AbsenceAction) => {
  if (action === AbsenceAction.Creation) return 'Erstellen';
  if (action === AbsenceAction.Update) return 'Aktualisieren';
  if (action === AbsenceAction.Deletion) return 'Löschen';

  return '-';
};
