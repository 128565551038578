import styled from 'styled-components';

export const Img = styled.img`
  border-radius: 50%;
  height: 6.25rem;
  width: 6.25rem;
`;

export const SmallImg = styled(Img)`
  height: 2.5rem;
  width: 2.5rem;
`;

export const LargeImg = styled(Img)`
  height: 7.5rem;
  width: 7.5rem;
`;
