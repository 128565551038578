declare global {
  interface Window {
    RuntimeConfiguration: {
      apiBaseUrl: string;
      avatarsUrl: string;
      employmentStatusIds: EmploymentStatusIds;
      documentTitle: string;
      lang: string;
      employmentStatusProbationMap: { [key: number]: number[] };
    };
  }
}

window.RuntimeConfiguration = window.RuntimeConfiguration || {};

const getRuntimeEnvironmentValue = <T>(key: string, fallbackValue?: T): T => {
  const runtimeConfigurationKey = '__RUNTIME_CONFIGURATION__';
  if (!(window as any)[runtimeConfigurationKey]) {
    throw new Error('No runtime configuration found');
  }
  const value = (window as any)[runtimeConfigurationKey][key];
  if (value === undefined) {
    if (fallbackValue !== undefined) {
      return fallbackValue;
    }
    throw new Error(`No runtime configuration for ${key} found`);
  }
  return value;
};

const deepFreeze = <T extends object>(o: T): Readonly<T> => {
  Object.freeze(o);
  Object.getOwnPropertyNames(o).forEach((prop) => {
    if (
      o.hasOwnProperty(prop) &&
      o[prop as keyof T] !== null &&
      (typeof o[prop as keyof T] === 'object' || typeof o[prop as keyof T] === 'function') &&
      !Object.isFrozen(o[prop as keyof T])
    ) {
      deepFreeze(o[prop as keyof T] as object);
    }
  });
  return o;
};

interface EmploymentStatusIds {
  trainee: number[];
  workingStudent: number[];
  thesisStudent: number[];
  intern: number[];
  leadingEmployee: number[];
  operativeEmployee: number[];
  temporaryHelp: number[];
  temporaryHelpStudent: number[];
}

const config: Window['RuntimeConfiguration'] = deepFreeze({
  apiBaseUrl: getRuntimeEnvironmentValue<string>('apiBaseUrl'),
  avatarsUrl: getRuntimeEnvironmentValue<string>('avatarsUrl'),
  employmentStatusIds: getRuntimeEnvironmentValue<EmploymentStatusIds>('employmentStatus'),
  documentTitle: getRuntimeEnvironmentValue<string>('documentTitle'),
  employmentStatusProbationMap: getRuntimeEnvironmentValue<{ [key: number]: number[] }>('employmentStatusProbationMap'),
  lang: 'de-DE',
});

export { config };
