import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { primary2, textLight } from 'shared/constants/colors';
import { Name } from 'shared/types/interfaces/Employee';
import { Avatar, AvatarSize, loadingStrategy } from 'components/Avatar';
import { Title3 } from 'components/Typography';

interface EmployeeShortInfoProps {
  employeeId: string;
  roleLabel?: string;
  name?: Name | { firstName: string; lastName: string };
  className?: string;
  fontSize?: string;
  offboardingId?: string | null;
  loading?: loadingStrategy;
  onClick?: () => void;
}

const LinkWrapper = styled(Link)`
  display: flex;
  align-items: center;
  margin: 0.25rem 0;
  font-weight: 400;
  color: ${textLight};
  text-decoration: none;
  text-align: start;
  box-sizing: border-box;
  padding-left: 1rem;
  &:hover {
    p {
      color: ${primary2};
    }
  }
`;

const NameWrapper = styled.div`
  margin-left: 0.5rem;
`;

const EmployeeShortInfo: React.FunctionComponent<React.PropsWithChildren<EmployeeShortInfoProps>> = ({
  employeeId,
  roleLabel,
  name,
  className,
  fontSize,
  offboardingId,
  loading,
}) => (
  <LinkWrapper
    to={offboardingId ? `/offboarding/detail/${offboardingId}` : `/employee/detail/${employeeId}`}
    className={className}
    style={{ fontSize: fontSize || '0.9375rem' }}
  >
    <Avatar uuid={employeeId} size={AvatarSize.SMALL} loading={loading} />
    {name && (
      <NameWrapper>
        {roleLabel ? (
          <Title3 title={roleLabel} subtitle={`${name.firstName} ${name.lastName}`} />
        ) : (
          <p>
            {name.firstName} {name.lastName}
          </p>
        )}
      </NameWrapper>
    )}
  </LinkWrapper>
);

export { EmployeeShortInfo };
