export enum CurrentStatus {
  CURRENT = 'CURRENT',
  PAST = 'PAST',
  FUTURE = 'FUTURE',
  OFFBOARDING_STARTED = 'OFFBOARDING_STARTED',
  ABSENT = 'ABSENT',
  ABSENT_WITH_OFFBOARDING = 'ABSENT_WITH_OFFBOARDING',
  // ALL = '',
}

export const CurrentStatusDisplayName = {
  // [CurrentStatus.ALL]: 'Alle',
  [CurrentStatus.CURRENT]: 'Aktuell',
  [CurrentStatus.PAST]: 'Ehemalig',
  [CurrentStatus.FUTURE]: 'Zukünftig',
  [CurrentStatus.OFFBOARDING_STARTED]: 'Offboarding laufend',
  [CurrentStatus.ABSENT]: 'Abwesend',
  [CurrentStatus.ABSENT_WITH_OFFBOARDING]: 'Abwesend (Offboarding laufend)',
};
