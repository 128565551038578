import * as React from 'react';
import { getFromServer, pushToServer } from 'apis/api';
import { FetchError } from 'apis/FetchError';
import { SharedContext } from 'shared/types/interfaces/Api';
import { User } from 'shared/types/interfaces/User';
import { authenticate } from './authenticate';
import { LoadingSpinner } from 'components/LoadingSpinner';

interface RequestManagerState {
  user: User | null;
  error: FetchError | null;
  isAuthenticating: boolean;
  contextValue: SharedContext;
}

// Global context
const Ctxt: React.Context<SharedContext> = React.createContext<SharedContext>({
  isLoggedIn: () => false,
  getUser: () => null,
  isAuthenticating: () => true,
  fetch: getFromServer,
  push: pushToServer,
  setFullErrorPage: (e: FetchError) => {},
});

class RequestManager extends React.Component<React.PropsWithChildren<{}>, RequestManagerState> {
  // NOTE: some things can be cached here
  // private cache = {};
  private setFullErrorPage: (e: FetchError) => void;

  /* This is a temporary workaround to avoid the test warnings regarding React state updates on an unmounted component.
  Instead the promise should be cancelable, see https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html */
  private mounted = false;

  constructor(props: React.PropsWithChildren<{}>) {
    super(props);

    this.setFullErrorPage = (error: FetchError) => {
      if (this.mounted) {
        this.setState((state) => ({
          error,
        }));
      }
    };

    this.state = {
      user: null,
      error: null,
      isAuthenticating: true,
      contextValue: {
        isLoggedIn: () => false,
        getUser: () => null,
        isAuthenticating: () => true,
        fetch: getFromServer,
        push: pushToServer,
        setFullErrorPage: this.setFullErrorPage,
      },
    };
  }

  public componentDidMount() {
    this.mounted = true;

    authenticate().then(
      (user) => {
        if (this.mounted) {
          this.setState({
            user,
            error: null,
            isAuthenticating: false,
            contextValue: {
              isLoggedIn: () => user !== null,
              getUser: () => user,
              isAuthenticating: () => this.state.isAuthenticating,
              fetch: getFromServer,
              push: pushToServer,
              setFullErrorPage: this.setFullErrorPage,
            },
          });
        }
      },
      (error) => {
        const fetchError = error as FetchError;
        const errorStatus: number = fetchError?.getResponse && fetchError.getResponse()?.status;

        // Http 401 Errors are handled via 'auth-failure-url' after redirect
        if (this.mounted && errorStatus !== 401) {
          this.setState({ error, isAuthenticating: false });
        }
      }
    );
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render() {
    const { error, isAuthenticating } = this.state;

    // DEV hint: create-react-app produces an error overlay in dev mode if error boundary has caught an error
    // https://github.com/facebook/create-react-app/issues/3627
    if (error && !isAuthenticating) {
      throw error;
    }

    return (
      /* Set context via state to prevent re-renderings
     https://reactjs.org/docs/context.html#caveats */
      <Ctxt.Provider value={this.state.contextValue}>
        {this.props.children}
        {!error && this.state.isAuthenticating && <LoadingSpinner />}
      </Ctxt.Provider>
    );
  }
}

export { RequestManager, Ctxt as ApiContext };
