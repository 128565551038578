import * as React from 'react';
import { useState, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getFromServer } from 'apis/api';
import { teamResourceUrl } from 'shared/constants/endpoints';
import { Team } from 'shared/types/interfaces/Employee';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { TeamContext, TeamProvider } from 'components/Provider';
import { connectToApi } from 'components/RequestManager/connectToApi';
import { ApiProps } from 'shared/types/interfaces/Api';
import { routes } from './routes';
import { config } from 'config';
import { GlobalStyle } from 'shared/style/styling';
import { Redirect } from 'react-router';

const About = lazy(() => import('components/About'));

export const getTeams = (apiTeams: Team[]): { [key: string]: string } => {
  const teams: { [key: string]: string } = {};
  apiTeams.forEach((team: Team) => (teams[team.id] = team.name.replace('org-', '')));

  return teams;
};

const App: React.FunctionComponent<React.PropsWithChildren<ApiProps>> = React.memo((props) => {
  const [teams, setTeams] = useState<TeamContext>({
    teams: {},
  });

  if (config.documentTitle !== null) {
    document.title = config.documentTitle;
  }

  // connect nav-drawer state to localstorage
  React.useEffect(() => {
    if (props.api.isLoggedIn()) {
      getFromServer<Team[]>(teamResourceUrl).then(
        (apiTeams: Team[]) => {
          const currTeams: TeamContext = {
            teams: getTeams(apiTeams),
          };
          setTeams(currTeams);
        },
        (error) => {
          props.api.setFullErrorPage(error);
        }
      );
    }
  }, [props.api]);

  if (props.api.isLoggedIn()) {
    // If user is logged in, render the app
    return (
      <TeamProvider value={teams}>
        <GlobalStyle />
        <React.Suspense fallback={<LoadingSpinner />}>
          <Switch>
            <Route exact path="/">
              <Redirect to={routes.employee_overview.url}></Redirect>
            </Route>
            <Route path={routes.employee_overview.url} component={routes.employee_overview.component} />
            <Route path={routes.onboarding.url} component={routes.onboarding.component} />
            <Route path={routes.offboarding.url} component={routes.offboarding.component} />
            <Route path={routes.salary.url} component={routes.salary.component} />
            <Route path={routes.absences.url} component={routes.absences.component} />
            <Route path={routes.reports.url} component={routes.reports.component} />
            <Route exact path={routes.about.url} component={About} />
            <Route render={routes.notFound.component} />
          </Switch>
        </React.Suspense>
      </TeamProvider>
    );
  } else {
    // If the request is pending, show the loading page
    return <LoadingSpinner />;
  }
});

const connectedApp = connectToApi(App);

export { connectedApp as App };
