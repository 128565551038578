import styled from 'styled-components';
import { primary1, white } from 'shared/constants/colors';

interface TableHeaderLabelProps {
  $showLabel: boolean;
}

interface HeaderElementWrapperProps {
  $interactive: boolean;
}

interface ButtonProps {
  $interactive: boolean;
}

/**
 * REFACTORED STYLED COMPONENTS
 */
export const HeaderElementWrapper = styled.div<HeaderElementWrapperProps>`
  z-index: 11;
  white-space: nowrap;
  padding: 3.75rem 1.875rem 1rem 0;
  color: rgb(75, 76, 91);
  cursor: ${({ $interactive: $interactive }: HeaderElementWrapperProps) => ($interactive ? 'pointer' : 'default')};
  background: ${white};
  border-bottom: 3px solid rgb(223, 223, 224);
  &:hover {
    &:after {
      transform: ${({ $interactive: $interactive }: HeaderElementWrapperProps) =>
        $interactive ? 'scaleX(1)' : 'none'};
    }
  }
  &:after {
    position: absolute;
    display: block;
    content: '';
    height: 1rem;
    width: 100%;
    border-bottom: ${({ $interactive: $interactive }: HeaderElementWrapperProps) =>
      $interactive ? `3px solid ${primary1}` : 'none'};
    transform: scaleX(0);
    transition: transform 150ms ease-in-out;
  }
`;

export const TableHeaderLabel = styled.span<TableHeaderLabelProps>`
  visibility: ${(props: TableHeaderLabelProps) => (props.$showLabel ? 'inherit' : 'hidden')};
  font-size: 0.8125rem;
  text-transform: uppercase;
  vertical-align: top;
  margin-left: 0.375rem;
`;

export const OutsideClickWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;

export const HeaderFilterContainer = styled.div`
  width: 11rem;
  z-index: 11;
`;

export const FilterWrapper = styled.div`
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.09);
`;

export const FilterCard = styled.div`
  padding: 0.375rem 0;
  margin-bottom: 0.3125rem;
  border-radius: 0.375rem;
  box-shadow: 0 0.1875rem 1.25rem rgba(0, 0, 0, 0.17);
  background: ${white};
  z-index: 11;
  &:first-child {
    border-top-left-radius: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const BigFilterCard = styled(FilterCard)`
  width: 14.75rem;
`;

export const BigSortWrapper = styled(FilterCard)`
  padding: 0.625rem 0.625rem;
  width: 14.75rem;
  display: flex;
  align-items: center;
  box-shadow: 0 0.1875rem 1.25rem rgba(0, 0, 0, 0.17);
  justify-content: space-between;
`;

export const SortWrapper = styled(FilterCard)`
  padding: 0.625rem 0.625rem;
  display: flex;
  align-items: center;
  box-shadow: 0 0.1875rem 1.25rem rgba(0, 0, 0, 0.17);
  justify-content: space-between;
`;

export const OffboardingDateSortWrapper = styled(BigSortWrapper)`
  width: 12.5rem;
  @media screen and (max-width: 1440px) {
    width: 9.85rem;
  }
`;

export const SortOptionLabel = styled.span`
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  z-index: 11;
`;

export const SortButtonContainer = styled.div`
  > button {
    margin-top: 0.1rem;
    margin-left: 0.5rem;
  }
  z-index: 11;
`;

export const Button = styled.button<ButtonProps>`
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  cursor: ${(props: ButtonProps) => (props.$interactive ? 'pointer' : 'default')};
  > img {
    transition: all 0.1s ease-in-out;
    &:hover {
      transform: ${(props: ButtonProps) => (props.$interactive ? 'scale(1.1)' : 'none')};
    }
  }
`;

export const ScrollFilter = styled(FilterCard)`
  max-height: 21rem;
  overflow-y: scroll;
  width: 16rem;
  z-index: 11;
`;
