export enum SalaryPeriod {
  HOUR = 'HOUR',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export const SalaryPeriodDisplayName = {
  [SalaryPeriod.HOUR]: 'stündlich',
  [SalaryPeriod.MONTH]: 'monatlich',
  [SalaryPeriod.YEAR]: 'jährlich',
};
