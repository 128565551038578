import * as React from 'react';
import { config } from 'config';
import { Img, LargeImg, SmallImg } from './Avatar.styled';

interface AvatarProps {
  uuid: string;
  size?: AvatarSize;
  className?: string;
  loading?: loadingStrategy;
}

export enum AvatarSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type loadingStrategy = 'eager' | 'lazy' | undefined;

export const Avatar: React.FunctionComponent<React.PropsWithChildren<AvatarProps>> = ({ uuid, size, className, loading = 'eager' }) => {
  switch (size) {
    case AvatarSize.SMALL:
      return <SmallImg src={config.avatarsUrl + '/' + uuid + '/' + size} className={className} loading={loading} />;
    case AvatarSize.LARGE:
      return <LargeImg src={config.avatarsUrl + '/' + uuid + '/' + size} className={className} loading={loading} />;
    case AvatarSize.MEDIUM:
    default:
      return <Img src={config.avatarsUrl + '/' + uuid + '/medium'} className={className} />;
  }
};
