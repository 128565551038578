import styled, { css } from 'styled-components';
import { greyLight, grey, primary3, textMedium, boxShadow } from 'shared/constants/colors';

export const PageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const LoadingSpinnerWrapper = styled.div`
  flex: 1 1 auto;
`;

export const MonthOverviewTable = styled.table<{
  $defaultColumnWidth?: string;
}>`
  border-spacing: 0;
  border: none;
  border-collapse: collapse;
  white-space: nowrap;
  table-layout: fixed;
  width: 100%;

  .detail {
    tr {
      cursor: pointer;
      &:hover {
        span {
          color: ${primary3};
        }
      }
    }
  }

  tr {
    box-sizing: border-box;
    &.active {
      background-color: ${greyLight};
      span {
        color: ${primary3};
      }
    }

    &:nth-child(n + 2):hover {
      border-bottom: solid ${grey} 2px;
    }
  }

  th {
    position: sticky;
    top: 0;
    padding: 0;
    text-align: left;
    font-weight: 400;
    font-size: 0.8125rem;
    width: ${(props) => (props.$defaultColumnWidth ? props.$defaultColumnWidth : '200px')};
    @media screen and (min-width: 1600px) {
      &:last-child {
        width: 100%;
      }
    }
  }

  td {
    padding: 0.5rem 0;
    min-width: 1%;
    &:first-child {
      padding: 0.5rem 0 0.5rem 1rem;
    }
    &:last-child {
      width: 100%;
    }
  }
`;

export const MonthOverviewHeaderRow = styled.tr`
  width: 100%;
  td {
    border: none;
    padding: 1rem !important;
    background-color: ${grey} !important;
    z-index: 5;
    &:first-child {
      z-index: 6;
      position: sticky;
      left: 0;
    }
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9375rem;
    color: ${textMedium};
    box-sizing: border-box;
  }
  &:hover {
    transform: scale(1) !important;
    border-bottom: none !important;
  }
`;

export const TableHeader = styled.th<{
  $stickyLeft?: string;
  width?: string;
}>`
  white-space: nowrap;
  padding: 0;
  z-index: 10;
  text-align: left;
  font-weight: 400;
  font-size: 0.8125rem;
  position: sticky;
  background-color: white;
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}!important;
    `};

  ${(props) =>
    props.$stickyLeft &&
    css`
      position: sticky;
      top: 0;
      left: ${props.$stickyLeft};
      z-index: 9999 !important;
      box-shadow: 5px 0 3px -3px ${boxShadow};
    `};
`;

export const NoTableEntries = styled.div`
  width: 100%;
  padding: 1rem;
  text-align: center;
  display: table-caption;
`;
