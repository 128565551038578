import styled from 'styled-components';
import { textDark } from 'shared/constants/colors';

export const SalaryText = styled.span`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  line-height: 1.5rem;
  text-align: left;
  min-width: 0;
  margin: 0;
`;

export const SalaryWithCurrency = styled.span`
  display: flex;
  align-items: flex-start;

  &:after {
    content: attr(data-period-text);
    min-width: 5.5rem;
    display: block;
    float: right;
    margin-left: 1ex;
  }
  color: ${textDark};
`;
