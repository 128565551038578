import { compile } from 'path-to-regexp';
import React, { lazy } from 'react';
import ErrorPages from 'components/ErrorPages';
import { SalaryOverview } from 'components/SalaryOverview/SalaryOverview';
import { AbsencesOverview } from 'components/AbsencesOverview/AbsencesOverview';

const OffboardingDetail = lazy(() => import('components/Offboarding/OffboardingDetail/OffboardingDetail'));

const EmployeeOverview = lazy(() => import('components/EmployeeOverview'));
const EmployeeDetail = lazy(() => import('components/EmployeeDetail/EmployeeDetail'));

const OffboardingHome = lazy(() => import('components/Offboarding'));
const OnboardingHome = lazy(() => import('components/Onboarding'));
const About = lazy(() => import('components/About'));

const Reports = lazy(() => import('components/Reports/Reports'));

const pathMatchers = {
  matchEmployee: `/employee/detail/:id`,
};
const compiledUrls = {
  matchEmployee: compile(pathMatchers.matchEmployee),
};

// Offboarding
const pathOffboardingMatchers = {
  matchOffboarding: `/offboarding/detail/:id`,
};
const compiledOffboardingUrls = {
  matchOffboarding: compile(pathOffboardingMatchers.matchOffboarding),
};

// Absences
const pathAbsenceMatchers = {
  matchAbsence: `/absences/detail/:id`,
};
const compiledAbsenceUrls = {
  matchAbsence: compile(pathAbsenceMatchers.matchAbsence),
};

interface EmployeeDetailParams {
  id: string;
}

export const routes = {
  employee_detail: {
    url: pathMatchers.matchEmployee,
    createUrl: (params: EmployeeDetailParams) => compiledUrls.matchEmployee(params),
    component: EmployeeDetail,
  },
  unauthorized: {
    url: '/unauthorized',
    component: () => <ErrorPages error={new Error('401')} />,
  },
  notFound: {
    url: '/notFound',
    component: () => <ErrorPages error={new Error('404')} />,
  },
  employee_overview: {
    url: '/employee',
    component: EmployeeOverview,
  },
  onboarding: {
    url: '/onboarding',
    component: OnboardingHome,
  },
  offboarding: {
    url: '/offboarding',
    component: OffboardingHome,
  },
  offboarding_detail: {
    url: pathOffboardingMatchers.matchOffboarding,
    createUrl: (params: EmployeeDetailParams) => compiledOffboardingUrls.matchOffboarding(params),
    component: OffboardingDetail,
  },
  salary: {
    url: '/salary',
    component: SalaryOverview,
  },
  absences: {
    url: '/absences',
    component: AbsencesOverview,
  },
  absence_detail: {
    url: pathAbsenceMatchers.matchAbsence,
    createUrl: (params: EmployeeDetailParams) => compiledAbsenceUrls.matchAbsence(params),
    // component: EmployeeDetail,
    component: () => <ErrorPages error={new Error('413')} />,
  },
  reports: {
    url: '/reports',
    component: Reports,
  },
  about: {
    url: '/about',
    component: About,
  },
};
