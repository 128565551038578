import { InoNavDrawerStyled, Main } from './NavDrawer.styled';
import { NavDrawerContent } from './NavDrawerContent';
import * as React from 'react';
import { useLocalStorageState } from 'shared/hooks/useLocalStorage';

export const NavDrawer: React.FunctionComponent<React.PropsWithChildren<any>> = (props) => {
  const [navOpen, setNavOpen] = useLocalStorageState<boolean>('nav-drawer', false);

  const setNavDrawerState = (e: CustomEvent<boolean>) => {
    localStorage.setItem('nav-drawer', e.detail.toString());
    setNavOpen(e.detail);
  };

  return (
    <InoNavDrawerStyled onOpenChange={setNavDrawerState} open={navOpen} className="nav-drawer">
      <NavDrawerContent />
      <Main slot="app">{props.children}</Main>
    </InoNavDrawerStyled>
  );
};
