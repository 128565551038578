import { Link } from 'react-router-dom';
import { inovex } from 'shared/constants/colors';
import styled from 'styled-components';
import { Logo } from 'components/Logo';

export const JamesLogo = styled(Logo)`
  display: block;
  height: auto;
  margin: auto;
  width: 3.125rem;
  padding-top: 8px;
`;

export const InovexWrapper = styled.div`
  color: ${inovex};
  display: center;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-size: 0.7188rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin: 1.25rem auto 3rem;
`;

export const StyledNavLink = styled(Link)`
  display: block;
  text-decoration: none;
`;
