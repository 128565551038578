import React, { useContext } from 'react';
import styled from 'styled-components';
import { AbsenceDashboardAbsenceItem } from 'shared/types/interfaces/Absences';
import { MaternityLeaveAbsenceDetails } from './MaternatyLeaveAbsence';
import { ParentalLeaveAbsenceDetails } from './ParentalLeaveAbsence';
import { SabbaticalLeaveAbsenceDetails } from './SabbaticalLeaveAbsence';
import { greyLight, iconGreyLight, primary1, textMedium } from 'shared/constants/colors';
import { HeadingPrimary } from 'components/Typography';
import { CloseViewIconButton } from 'components/CloseViewIconButton';
import { copyToClipboard, mapActionToText } from 'components/AbsencesOverview/utils';

interface AbsenceDetailProps {
  absence: AbsenceDashboardAbsenceItem;
  onDetailClosed?: () => void;
}

export const DetailAttribute = styled.div`
  display: grid;
  grid-template-columns: 28px auto;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const DetailAttributeIcon = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  width: 24px;
`;

export const DetailAttributeTextWrapper = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  padding-top: 2px;
  cursor: pointer;
`;

export const ClipboardTextWrapper = styled.span`
  cursor: pointer;
`;

export const ListDetail = styled.ul`
  margin: 0px;
  padding: 0px;

  li {
    list-style-type: none;
    padding-left: 0px;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    background-size: 24px 24px !important;

    a,
    a:hover,
    a:active,
    a:visited,
    a:focus {
      text-decoration: none;
      color: ${textMedium};
      &:hover {
        p {
          color: ${primary1};
        }
      }
    }
  }
`;

export const RoundedDiv = styled.div`
  border-radius: 50%;
  background-color: ${greyLight};
  display: flex;
  vertical-align: middle;
  justify-content: center;
  margin: auto;
  text-align: Center;
  width: 24px;
  height: 24px;
  align-items: center;
  color: ${iconGreyLight};

  ino-icon {
    height: 15px;
    width: 15px;
  }
`;

export const ClipboardText: React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<{}>>> = (props) => (
  <ClipboardTextWrapper
    onClick={() => {
      (props.children && typeof props.children === 'string') || typeof props.children === 'number'
        ? copyToClipboard(props.children?.toString())
        : null;
    }}
  >
    {props.children}
  </ClipboardTextWrapper>
);

export const DetailAttributeText: React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<{}>>> = (
  props
) => (
  <DetailAttributeTextWrapper>
    <ClipboardText>{props.children}</ClipboardText>
  </DetailAttributeTextWrapper>
);

const AbsenceDetail: React.FunctionComponent<React.PropsWithChildren<AbsenceDetailProps>> = (props) => {
  const selectedAbsence = props.absence;

  const renderDetailsComponent = (absence: any) => {
    if (absence.absenceType === 'maternity_leave') {
      return (
        <MaternityLeaveAbsenceDetails
          id={absence.absenceId}
          birthdate={absence.details.birthdate}
          expectedBirthdate={absence.details.expectedBirthdate}
          leaveLengthAfterBirth={absence.details.leaveLengthAfterBirth}
          absence={absence}
        ></MaternityLeaveAbsenceDetails>
      );
    } else if (absence.absenceType === 'sabbatical_leave') {
      return (
        <SabbaticalLeaveAbsenceDetails
          id={absence.absenceId}
          documentLink={absence.details.documentLink}
          start={absence.details.start}
          end={absence.details.end}
          startSaving={absence.details.savingStart}
          absence={absence}
        ></SabbaticalLeaveAbsenceDetails>
      );
    } else if (absence.absenceType === 'parental_leave') {
      return (
        <ParentalLeaveAbsenceDetails
          id={absence.absenceId}
          start={absence.details.start}
          end={absence.details.end}
          absence={absence}
        ></ParentalLeaveAbsenceDetails>
      );
    } else {
      return <div>-</div>;
    }
  };

  return (
    <Wrapper>
      <CloseButtonWrapper>
        <div
          onClick={() => {
            props.onDetailClosed ? props.onDetailClosed() : null;
          }}
        >
          <CloseViewIconButton to="/absences" />
        </div>
      </CloseButtonWrapper>
      <InlineWrapper>
        <HeadingPrimary>
          Abwesenheit {selectedAbsence.employee.name.firstName} {selectedAbsence.employee.name.lastName}
        </HeadingPrimary>
        <p>Aktion: {mapActionToText(selectedAbsence.action)}</p>
      </InlineWrapper>
      <InlineWrapper>{renderDetailsComponent(selectedAbsence)}</InlineWrapper>
    </Wrapper>
  );
};

const CloseButtonWrapper = styled.div`
  a {
    position: relative;
    top: 0px;
    left: 0px;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  padding: 20px;
`;

const InlineWrapper = styled.div`
  padding-top: 25px;
`;

export const AbsenceWrapper = styled.div`
  height: 100%;
`;

export default AbsenceDetail;
