import * as React from 'react';
import { InoTooltip } from '@inovex.de/elements-react';
import { iconGreyMedium } from 'shared/constants/colors';
import { CustomInoIcon } from 'shared/style/CustomInoIcon';
import { formatDateToString } from 'shared/utils/utils';
import {
  ListDetail,
  DetailAttribute,
  DetailAttributeText,
  DetailAttributeIcon,
  AbsenceWrapper,
} from './AbsencesDetail';
import { AbsenceDetailHeader } from './AbsenceDetailHeader';
import { AbsenceDashboardAbsenceItem } from 'shared/types/interfaces/Absences';
import { InoHeader } from 'shared/components/InoHeader';

interface MaternityLeaveProps {
  id: number;
  birthdate: string;
  expectedBirthdate: string;
  leaveLengthAfterBirth: number;
  absence: AbsenceDashboardAbsenceItem;
}

export const MaternityLeaveAbsenceDetails: React.FunctionComponent<React.PropsWithChildren<MaternityLeaveProps>> = (
  props
) => (
  <AbsenceWrapper>
    <InoHeader $firstHeading>Mutterschutz</InoHeader>
    <AbsenceDetailHeader start={props.absence.start} end={props.absence.end}></AbsenceDetailHeader>
    <ListDetail>
      <li id={'li-birthday-' + props.id}>
        <DetailAttribute>
          <DetailAttributeText>{formatDateToString(props.birthdate)}</DetailAttributeText>
          <DetailAttributeIcon>
            <CustomInoIcon
              src={process.env.PUBLIC_URL + '/icons/pediatrics_icon.svg'}
              color={iconGreyMedium}
              size={'1.2rem'}
            ></CustomInoIcon>
          </DetailAttributeIcon>
        </DetailAttribute>
      </li>
      <li id={'li-expected-birthday-' + props.id}>
        <DetailAttribute>
          <DetailAttributeText>{formatDateToString(props.expectedBirthdate)}</DetailAttributeText>
          <DetailAttributeIcon>
            <CustomInoIcon
              src={process.env.PUBLIC_URL + '/icons/doctor-gynecologist-icon.svg'}
              color={iconGreyMedium}
              size={'1.2rem'}
            ></CustomInoIcon>
          </DetailAttributeIcon>
        </DetailAttribute>
      </li>
      <li id={'li-maternity-' + props.id}>
        <DetailAttribute>
          <DetailAttributeText>{props.leaveLengthAfterBirth === 8 ? 'normal' : 'erweitert'}</DetailAttributeText>
          <DetailAttributeIcon>
            <CustomInoIcon
              src={process.env.PUBLIC_URL + '/icons/type-icon.svg'}
              color={iconGreyMedium}
              size={'1.2rem'}
            ></CustomInoIcon>
          </DetailAttributeIcon>
        </DetailAttribute>
      </li>
      <InoTooltip for={'li-birthday-' + props.id} placement="right" trigger="mouseenter" colorScheme={'light'}>
        {'Geburtstermin tatsächlich'}
      </InoTooltip>
      <InoTooltip for={'li-expected-birthday-' + props.id} placement="right" trigger="mouseenter" colorScheme={'light'}>
        {'Geburtstermin errechnet'}
      </InoTooltip>
      <InoTooltip for={'li-maternity-' + props.id} placement="right" trigger="mouseenter" colorScheme={'light'}>
        {'Art'}
      </InoTooltip>
    </ListDetail>
  </AbsenceWrapper>
);
