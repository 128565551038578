import * as React from 'react';
import inovexLogo from './inovex-logo-new.svg';
import jamesLogo from './james-logo.png';
import { LogoImage } from './Logo.styled';

const Logos = {
  inovex: inovexLogo,
  james: jamesLogo,
};

interface LogoProps {
  type?: keyof typeof Logos;
  className?: string;
}

export class Logo extends React.PureComponent<LogoProps> {
  public render() {
    const { type = 'inovex', className } = this.props;
    return <LogoImage src={Logos[type]} alt="" className={className} />;
  }
}
