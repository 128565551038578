import { InoHeaderStyled, InoHeaderContainer } from 'components/EmployeeDetail/EmployeeDetailView/Tabs/Tab.styled';
import * as React from 'react';

export const InoHeader: React.FunctionComponent<React.PropsWithChildren<{ $firstHeading?: boolean }>> = ({
  $firstHeading,
  children,
}) => (
  <InoHeaderContainer $firstHeading={$firstHeading}>
    <InoHeaderStyled>{children}</InoHeaderStyled>
  </InoHeaderContainer>
);
