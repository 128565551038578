export const white = '#ffffff';

// UI Colors
export const inovex = '#003C7E';
export const primary1 = '#3d40f5';
export const primary2 = '#5d60d7';
export const primary3 = '#0d10f3';
export const secondary1 = '#9ccd00';
export const secondary2 = '#bbd962';
export const secondary3 = '#93bf00';
export const error = '#eb003b';
export const grey = '#ededf0';
export const greyLight = '#f3f3f3';
export const iconGreyLight = '#989898';
export const iconGreyMedium = '#646262';
export const iconGreyDark = '#282828';
export const hover = 'rgba(61, 64, 245, 0.03)';
export const selected = 'rgba(0, 0, 0, 0.04)';
export const modal = 'rgba(76, 77, 91, 0.2)';
export const boxShadow = 'rgba(0, 0, 0, 0.2)';

// Text Colors
export const textDark = '#000000';
export const textMedium = '#525368';
export const textLight = '#74758B';
export const textDisabled = '#9E9E9E';

// Extra Colors
export const headingDecorator = '#f2f2f2';
